import React, { useState } from "react";
import styled from "styled-components";

const State = styled.div`
  //margin: 30px;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  border: ${(props) =>
    props.rejected && props.approved && props.waiting === false
      ? "1px solid #0078b4"
      : null};
  background-color: ${(props) => {
    if (props.rejected) {
      return "#be2321";
    } else if (props.waiting) {
      return "#FFB115";
    } else if (props.approved) {
      return "#419219";
    } else if ((props.rejected && props.approved && props.waiting) === false) {
      return "#0078b4";
    }
  }};
  @media (max-width: 1113px) {
    //height: 15px;
    //width: 15px;
  }
`;
const StateText = styled.span`
  color: ${(props) => {
    if (props.rejected) {
      return "#be2321";
    } else if (props.waiting) {
      return "#FFB115";
    } else if (props.approved) {
      return "#419219";
    } else if ((props.rejected && props.approved && props.waiting) === false) {
      return "#0078b4";
    }
  }};
  margin-left: 40px;
  margin-top: 4px;
  float: left;
  width: 200px;
  @media (max-width: 1113px) {
    //font-size: 0.7em;
    //margin-left: 25px;
    //width: 100px;
    //margin-top: 1px;
  }
`;

const FairState = ({ formState }) => {
  return (
    <>
      <State
        rejected={formState === "Zamítnuto"}
        waiting={formState === "Čeká na schválení"}
        approved={formState === "Schváleno"}
      >
        <StateText
          rejected={formState === "Zamítnuto"}
          waiting={formState === "Čeká na schválení"}
          approved={formState === "Schváleno"}
        >
          {formState}
        </StateText>
      </State>
    </>
  );
};

export default FairState;
