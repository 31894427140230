import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import Modal from "@material-ui/core/Modal";
import OrderModalBody from "./OrderModalBody.container";

const Lath = styled.div`
  border-radius: 8px;
  position: absolute;
  width: 100%;
  height: 60px;
  top: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
`;
const Close = styled.span`
  color: #979ca2;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-right: 10px;
  &:hover {
    color: #0078b4;
    text-decoration: none;
    cursor: pointer;
  }
`;
const Image = styled.div`
  width: 100%;
  background-size: cover;
  height: 40vh;
  background-repeat: no-repeat;
  border-radius: 8px 8px 0 0;
  background-position: center;
  background-image: url(${(props) =>
    props.src ? process.env.REACT_APP_URL + props.src : null});
  @media (min-height: 1500px) {
    height: 450px;
  }
`;
const CloseButton = styled.button`
  border: none;
  color: #484848;
  background-color: transparent;
  padding: 0px 10px;
  border-radius: 3px;
  /* margin: 20px 7% 20px 7%; // WITH RECAPTCHA */
  margin: 0px 7% 20px 7%;
  outline: none;
  position: relative;
  top: -10px;
  cursor: pointer;
  @media (max-width: 665px) {
    margin: 20px 0 0 7%;
    //float: left;
  }
`;

function getModalStyle () {
  return {
    top: "50%",
    left: "50%",
    transform: `translate(-${50}%, -${50}%)`
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    "position": "absolute",
    "width": "450px",
    "height": "95vh",
    "overflow": "scroll",
    "backgroundColor": theme.palette.background.paper,
    "border": "none",
    "outline": "none",
    "borderRadius": "8px",
    "boxShadow": theme.shadows[5],
    "@media (max-width: 665px)": {
      width: "320px",
      left: "0",
      top: "0",
      height: "auto",
      paddingBottom: "10px"
    }
  }
}));

const OrderModal = ({
  modalIsOpened,
  setModalIsOpened,
  product,
  eFair,
  refetch
}) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  return (
    <div
      style={{
        borderRadius: "80px",
        overflow: "scroll"
      }}
    >
      <Modal
        open={modalIsOpened}
        onClose={() => setModalIsOpened(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Image src={`/api/files/${product.photoLinkPublic}`} />
          <Lath>
            <Close onClick={() => setModalIsOpened(false)}>&times;</Close>
          </Lath>
          <OrderModalBody product={product} eFair={eFair} refetch={refetch} />
          <CloseButton onClick={() => setModalIsOpened(false)}>
            Zavřít
          </CloseButton>
        </div>
      </Modal>
    </div>
  );
};

export default OrderModal;
