import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import issuingText from "../constants/issuingText";

const ProductsInfo = styled.p`
  color: #484848;
  width: 60%;
  margin-bottom: 40px;
  margin-left: 10px;
`;
const Subtitle = styled.h2`
  margin-top: 10px;
  color: #484848;
  margin-left: 10px;
  font-family: OpenSansBold, sans-serif;
  font-size: 1em;
`;
const Bold = styled.strong`
  font-weight: bold;
  font-family: OpenSansBold, sans-serif;
  font-size: 1.1em;
  color: #484848;
`;
const ReservationDate = styled.p`
  color: #484848;
  margin: 10px;
  font-family: OpenSansSemiBold, sans-serif;
  @media (max-width: 665px) {
    float: left;
  }
`;
export const ReservationsState = ({ isActive, eFair }) => {
  const [ongoingIssuing, setOngoingIssuing] = useState(false);
  useEffect(() => {
    if (eFair !== {} && eFair.issuingTime !== undefined) {
      for (let i = 0; eFair.issuingTime.length > i; i++) {
        if (moment(eFair.issuingTime[i].fromPublic).isAfter(Date.now())) {
          setOngoingIssuing(true);
          break;
        }
      }
    }
  }, [eFair, ongoingIssuing]);
  if (moment(eFair.from).isAfter(Date.now())) {
    return <ProductsInfo>{issuingText.NOT_STARTED}</ProductsInfo>;
  } else if (isActive) {
    return (
      <>
        <ProductsInfo>
          {issuingText.ALLOWED_RESERVATIONS.ADDRESS_TEXT}
          <Bold>{` ${eFair.issuingPlace.streetAndNumberPublic}, ${eFair.issuingPlace.cityPublic}, ${eFair.issuingPlace.zipCodePublic}`}</Bold>
          <i>
            {eFair.issuingPlace.notePublic !== null &&
            eFair.issuingPlace.notePublic !== ""
              ? ` (${eFair.issuingPlace.notePublic})`
              : null}
          </i>
          {". "}
        </ProductsInfo>
        <Subtitle>{issuingText.ALLOWED_RESERVATIONS.TIME_TEXT}</Subtitle>
        {eFair.issuingTime.length > 0
          ? eFair.issuingTime.map((time, index) => {
            return (
              <React.Fragment key={index}>
                <ReservationDate>
                  {`${moment(time.fromPublic).format(
                      "DD. MM. YYYY"
                    )} mezi ${moment(time.fromPublic).format(
                      "HH:mm"
                    )} a ${moment(time.toPublic).format("HH:mm")}`}
                </ReservationDate>
              </React.Fragment>
            );
          })
          : ""}
      </>
    );
  } else if (moment(eFair.to).isBefore(Date.now()) && ongoingIssuing) {
    return (
      <>
        <ProductsInfo>{issuingText.OVER_BUT_ISSUING}</ProductsInfo>
        <Subtitle>{issuingText.ALLOWED_RESERVATIONS.TIME_TEXT}</Subtitle>
        {eFair.issuingTime.length > 0
          ? eFair.issuingTime.map((time, index) => {
            return (
              <React.Fragment key={index}>
                <ReservationDate>
                  {`${moment(time.fromPublic).format(
                      "DD. MM. YYYY"
                    )} mezi ${moment(time.fromPublic).format(
                      "HH:mm"
                    )} a ${moment(time.toPublic).format("HH:mm")}`}
                </ReservationDate>
              </React.Fragment>
            );
          })
          : ""}
      </>
    );
  } else if (moment(eFair.to).isBefore(Date.now()) && !ongoingIssuing) {
    return null;
  } else {
    return null;
  }
};
