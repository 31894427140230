import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Headline = styled.div`
font-size: 1.2em;
font-weight: bold;
`;
const Info = styled.div`
font-size: 0.8em;
color: grey;
margin-top: 15px;
width: 90%;
`;
const Label = styled.div`
font-size: 0.8em;
color: grey;
margin: 25px 0 5px 0;
width: 90%;
`;
const NoteInput = styled.textarea`
outline: none;
width: 99%;
height: 100px;
border-radius: 6px;
padding: 10px;
box-sizing: border-box;
resize: none;
margin-top: 20px;
margin: 0 auto;
`;
const SubmitButton = styled.button`
  color: #fff !important;
  border-top-color: #1279b2;
  border-bottom-color: #072e62;
  box-shadow: inset 0 -2px 0 0 #072e62;
  outline: 0;
  background-color: #1279b2;
  width: 230px;
  height: 50px;
  //color: white;
  float: right;
  border: none;
  margin: 25px 0 0 0;
  border-radius: 4px;
  outline: none;
  &:hover {
    border-bottom-color: #1279b2;
    box-shadow: inset 0 -2px 0 0 #1279b2;
  }
  &:disabled {
    background-color: lightgrey;
    color: white;
    border-bottom-color: #a6a6a6;
    box-shadow: inset 0 -2px 0 0 #a6a6a6;
  }
  @media (max-width: 665px) {
    margin: 30px auto 0 auto;
    float: none;
    width: 100%;
  }
`;
const CloseButton = styled.button`
  border: none;
  color: #484848;
  background-color: transparent;
    padding: 0px 10px;
    outline: none;
  border-radius: 3px;
  margin: 40px 0 0 0;
    font-weight: bold;
    font-family: OpenSansBold, sans-serif;
  cursor: pointer;
  @media (max-width: 665px) {
    margin: 25px auto 0 auto;
    float: none;
    width: 100%;
  }
`;

function getModalStyle () {
  return {
    top: "50%",
    left: "50%",
    transform: `translate(-${50}%, -${50}%)`
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    "position": "absolute",
    "width": "700px",
    "height": "400px",
    "overflow": "scroll",
    "backgroundColor": theme.palette.background.paper,
    "border": "none",
    "padding": "20px",
    "boxSizing": "border-box",
    "outline": "none",
    "zIndex": "10000",
    "borderRadius": "8px",
    "boxShadow": theme.shadows[5],
    "@media (max-width: 665px)": {
      width: "320px",
      left: "0",
      top: "0",
      height: "auto",
      paddingBottom: "10px"
    }
  }
}));

const ChangeIssuingDayModal = ({
  reason,
  setReason,
  changeIssuingDayOnSubmitHandler,
  isModalOpened,
  setIsModalOpened,
  setIsAddedNewIssuingDay,
  setHasChangeIssuingDayModalAlreadyDisplayed
}) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  return (
    <Modal
      open={isModalOpened}
      onClose={() => setIsModalOpened(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <Headline>Změna výdejních termínů</Headline>
        <Info>Chystáte se změnit výdejní termíny. Lidé, kteří už si zarezervovali vaše výrobky, počítají s původním termínem. Pokud si opravdu přejete změnit termín, nevadí, my jim pošleme automatický e-mail s novými termíny :-)</Info>
        <Label>Sem napište proč termíny měníte, důvod se propíše do automatického e-mailu pro zákazníky:</Label>
        <NoteInput value={reason} onChange={(e) => setReason(e.target.value)} />
        <SubmitButton
          onClick={() => {
            changeIssuingDayOnSubmitHandler();
            setIsModalOpened(false);
          }}
        >Ano, chci změnit výdejní termíny.
        </SubmitButton>
        <CloseButton
          onClick={() => {
            setIsModalOpened(false);
          }}
        >
          Aha! Tak to nechci termíny měnit.
        </CloseButton>
      </div>
    </Modal>
  );
};

export default ChangeIssuingDayModal;
