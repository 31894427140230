import React, { Fragment, useEffect, useState } from "react";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import styled from "styled-components";

const MediaSection = ({ eFair, push }) => {
  const { openLightbox } = useLightbox();

  const isVideo = !(!eFair || eFair.videoLinkPublic === "" || eFair.videoLinkPublic === null);
  const images = (!!eFair && !!eFair.images) ? eFair.images : [];

  if (eFair === null || eFair === {} || eFair.state === "Skrytý") {
    push("/hledat");
  }

  const callbacks = {
    onSlideChange: object => {
      console.log(object);
      setTimeout(function () {
        const el = document.getElementsByClassName("SRLImage")[0];
        el.style.maxHeight = "850px";
        el.style.maxWidth = "950px";
      }, 700);
    },
    onLightboxOpened: object => {
      setTimeout(function () {
        const el = document.getElementsByClassName("SRLImage")[0];
        el.style.maxHeight = "850px";
        el.style.maxWidth = "950px";
      }, 500);
    }
  };

  return (
    <>
      <FlexWrap>
        {isVideo ? (
          <>
            <Column
              isVideo={isVideo}
              threeOrMoreImages={images.length > 2}
            >
              <Video
                width="100%"
                src={eFair.videoLinkPublic}
                allowFullScreen
              >
              </Video>
            </Column>
            <Column threeOrMoreImages={images.length > 2}>
              <PhotoWrap>
                <SRLWrapper>
                  {images.length > 0
                    ? images.map((image, index) => {
                      if (index > 3) {
                      }
                      if (
                        image.pathPublic !== null &&
                          image.pathPublic !== ""
                      ) {
                        return (
                          <Fragment key={index}>
                            <Image
                              onClick={() => openLightbox()}
                              isVideo={isVideo}
                              index={index}
                              src={`/api/files/${image.pathPublic}`}
                            />
                            <img
                              alt=""
                              style={{
                                display: "none"
                              }}
                              src={`/api/files/${image.pathPublic}`}
                            />
                            {index > 3 ? (
                              <OtherImages onClick={() => openLightbox()}>
                                <ImageCount>+{images.length - 4}</ImageCount>
                              </OtherImages>
                            ) : null}
                          </Fragment>
                        );
                      }
                    })
                    : "Na fotkách ještě pracujeme..."}
                </SRLWrapper>
              </PhotoWrap>
            </Column>
          </>
        ) : (
          <>
            <Column threeOrMoreImages={images.length > 2}>
              <NoVideoWrap>
                <SRLWrapper callbacks={callbacks}>
                  {images.length > 0
                    ? images.map((image, index) => {
                      if (
                        image.pathPublic !== null &&
                          image.pathPublic !== ""
                      ) {
                        return (
                          <Fragment key={index}>
                            <Image
                              onClick={() => openLightbox()}
                              isVideo={isVideo}
                              src={`/api/files/${image.pathPublic}`}
                            />
                            <img
                              alt=""
                              style={{
                                display: "none"
                              }}
                              src={`/api/files/${image.pathPublic}`}
                            />
                          </Fragment>
                        );
                      } else {
                        return null;
                      }
                    })
                    : "Na fotkách ještě pracujeme..."}
                </SRLWrapper>
              </NoVideoWrap>
            </Column>
          </>
        )}
      </FlexWrap>
    </>
  );
};

export default MediaSection;

const FlexWrap = styled.div`
  overflow: hidden;
  padding: 10px 0;
  max-width: 1200px;
  width: 100%;
  margin: auto;
  display: flex;
  @media (max-width: 665px) {
    flex-wrap: wrap;
    margin: 0 auto;
  }
`;
const Image = styled.div`
  width: ${(props) => (props.isVideo ? "48%" : "200px")};
  height: ${(props) => (props.isVideo ? "10rem" : "200px")};
  float: left;
  display: ${(props) => {
    if (props.index > 3) {
      return "none";
    } else {
      return "block";
    }
  }};
  object-fit: cover;
  overflow: hidden;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${(props) =>
    props.src ? process.env.REACT_APP_URL + props.src : null});
  align-self: start;
  margin: 0.5%;
  border-radius: 8px;
  transition: 0.5s;
  &:hover {
    transform: scale(1.03);
  }
`;
const PhotoWrap = styled.div`
  height: 100%;
  width: 100%;
  @media (max-width: 665px) {
    float: left;
  }
`;

const Column = styled.div`
  flex: 50%;
  height: ${(props) => (props.threeOrMoreImages ? "auto" : "365px")};
  position: relative;
  margin: 12px;
  @media (max-width: 665px) {
    float: left;
    height: auto;
  }
`;
const Video = styled.iframe`
  max-width: 100%;
  height: 99.3%;
  @media (max-width: 665px) {
    width: 100vh;
    height: 40vh;
  }
`;
const NoVideoWrap = styled.div`
  width: 100%;
  height: auto;
`;
const OtherImages = styled.div`
  background-color: black;
  opacity: 0.7;
  position: absolute;
  width: 48%;
  height: 49%;
  color: white;
  bottom: 0.5%;
  right: 2.5%;
  border-radius: 8px;
  text-align: center;
  &:hover {
    opacity: 0.9;
  }
`;
const ImageCount = styled.h1`
  color: white;
  margin-top: 30%;
  @media (max-width: 665px) {
    font-size: 13px;
  }
`;
