import React from "react";

import IntroBody from "../components/IntroBody.component";

const Intro = () => (
  <>
    <IntroBody />
  </>
);

export default Intro;
