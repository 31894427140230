import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import styled from "styled-components";

const Headline = styled.div`
  font-size: 1.2em;
  font-weight: bold;
`;
const Info = styled.div`
  font-size: 0.8em;
  color: grey;
  margin-top: 15px;
  width: 90%;
`;
const Label = styled.div`
  font-size: 0.8em;
  color: grey;
  margin: 25px 0 5px 0;
  width: 90%;
`;
const NoteInput = styled.textarea`
  outline: none;
  width: 100%;
  height: 100px;
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;
  resize: none;
  margin-top: 20px;
  margin: 0 auto;
`;
const SubmitButton = styled.button`
    color: #fff;
    background-color: #ff6129;
    &:hover {
      background-color: #fa4a11;
    }
	width: 230px;
	height: 50px;
	float: right;
	border: none;
	margin: 25px 0 0 0;
	border-radius: 4px;
	outline: none;
	&:disabled {
		background-color: lightgrey;
		color: white;
		border-bottom-color: #a6a6a6;
		box-shadow: inset 0 -2px 0 0 #a6a6a6;
	}
	@media (max-width: 665px) {
		margin: 30px auto 0 auto;
		float: none;
		width: 100%;
	}
`;
const CloseButton = styled.button`
	border: none;
	color: #484848;
	background-color: transparent;
    padding: 0px 10px;
    outline: none;
	border-radius: 3px;
	margin: 40px 0 0 0;
    font-weight: bold;
    font-family: OpenSansBold, sans-serif;
	cursor: pointer;
	@media (max-width: 665px) {
		margin: 25px auto 0 auto;
		float: none;
		width: 100%;
	}
`;

function getModalStyle () {
  return {
    top: "50%",
    left: "50%",
    transform: `translate(-${50}%, -${50}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    "position": "absolute",
    "width": "500px",
    "height": "500",
    "overflow": "scroll",
    "backgroundColor": theme.palette.background.paper,
    "border": "none",
    "padding": "20px",
    "boxSizing": "border-box",
    "outline": "none",
    "zIndex": "10000",
    "borderRadius": "8px",
    "boxShadow": theme.shadows[5],
    "@media (max-width: 665px)": {
      width: "320px",
      left: "0",
      top: "0",
      height: "auto",
      paddingBottom: "10px"
    }
  }
}));

const NoteModal = ({ onSubmitHandler, isModalOpened, setIsModalOpened, managerNote, setManagerNote, loading, saveStatus }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  return (
    <Modal
      open={isModalOpened}
      onClose={() => setIsModalOpened(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <Headline>Odeslat změny ke schválení</Headline>
        <Info>Změny odešleme ke schválení administrátorovi Abecedy peněz. Pokud budou změny akceptovány či zamítnuty, budete informováni e-mailem.</Info>
        {saveStatus.length > 0 && (
          <div style={{ border: '1px solid grey', padding: '0 12px', marginTop: '8px', borderRadius: '6px', height: '200px', overflow: 'auto' }}>
            <p style={{ fontWeight: 'bolder' }}>Průběh ukládání:</p>
            {saveStatus.map((message, i) => (
              <p key={i} style={{ color: message.type === 'success' ? 'green' : 'default', margin: 0 }}>- {message.text}</p>
            ))}
          </div>
        )}
        <Label>Pokud chcete, můžete ke změnám přidat poznámku:</Label>
        <NoteInput value={managerNote} onChange={(e) => setManagerNote(e.target.value)} />
        <SubmitButton disabled={loading} onClick={onSubmitHandler}>Odeslat změny ke schválení</SubmitButton>
        <CloseButton disabled={loading} onClick={() => setIsModalOpened(false)}>Zavřít a neodesílat</CloseButton>
      </div>
    </Modal>
  );
};

export default NoteModal;
