import DateFnsUtils from "@date-io/date-fns";
import { createMuiTheme } from "@material-ui/core";
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import cs from "date-fns/esm/locale/cs";
import moment from "moment";
import React, { FC, useContext } from "react";
import styled from "styled-components";
import { ErrorMessage } from ".";

import { FormContext } from "../../context/FormContext";
import { Wrap } from "./IssuingTimes";

interface Props {
  time: any;
  submitting: boolean;
  reservationsQuery: any;
  i: number;
}

export const IssuingDay: FC<Props> = ({ time, submitting, reservationsQuery, i }) => {
  const context = useContext(FormContext);

  return (
    <>
      <Wrap
        invalid={(time.to === null ||
                      time.to === "Invalid date" ||
                      time.from === null ||
                      time.from === "Invalid date") &&
                    submitting}
        className="mandatory"
      >
        <ThemeProvider theme={customTheme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
            <DatePicker
              disableToolbar
              variant="inline"
              format="dd.MM.yyyy"
              margin="normal"
              name="date"
              id="date-picker-inline"
              label="Datum"
              disabled={context.formDisabled}
              value={time.from}
              onChange={(value) => {
                if (!context.isAddedNewIssuingDay && !context.hasChangeIssuingDayModalAlreadyDisplayed && reservationsQuery.length > 0) {
                  context.setChangeIssuingDayModalOpened(true);
                  context.setChangeIssuingDayOnSubmitHandler(() => () => {
                    context.updateDateAndTime(value, time, i);
                    context.setHasChangeIssuingDayModalAlreadyDisplayed(false);
                    context.setIsAddedNewIssuingDay(false);
                  });
                } else {
                  context.updateDateAndTime(value, time, i);
                }
                if (!context.hasChangeIssuingDayModalAlreadyDisplayed) {
                  context.setHasChangeIssuingDayModalAlreadyDisplayed(true);
                }
              }}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
            <TimePicker
              margin="normal"
              id="time-picker-from"
              label="Od"
              okLabel="Uložit"
              cancelLabel="Zrušit"
              disabled={context.formDisabled}
              value={time.from}
              onChange={(value) => {
                if (!context.isAddedNewIssuingDay && !context.hasChangeIssuingDayModalAlreadyDisplayed && reservationsQuery.length > 0) {
                  context.setChangeIssuingDayModalOpened(true);
                  context.setChangeIssuingDayOnSubmitHandler(() => () => {
                    context.updateDateAndTime(value, time, i);
                    context.setHasChangeIssuingDayModalAlreadyDisplayed(false);
                    context.setIsAddedNewIssuingDay(false);
                  });
                } else {
                  context.updateDateAndTime(value, time, i);
                }
                if (!context.hasChangeIssuingDayModalAlreadyDisplayed) {
                  context.setHasChangeIssuingDayModalAlreadyDisplayed(true);
                }
              }}
              KeyboardButtonProps={{
                "aria-label": "change time"
              }}
              ampm={false}
            />
            <To />
            <TimePicker
              margin="normal"
              id="time-picker-to"
              label="Do"
              okLabel="Uložit"
              cancelLabel="Zrušit"
              disabled={context.formDisabled}
              value={moment(time.from).set({
                hour: moment(time.to).get("hour"),
                minute: moment(time.to).get("minute"),
                second: moment(time.to).get("second")
              }).toISOString()}
              onChange={(value) => {
                if (!context.isAddedNewIssuingDay && !context.hasChangeIssuingDayModalAlreadyDisplayed && reservationsQuery.length > 0) {
                  context.setChangeIssuingDayModalOpened(true);
                  context.setChangeIssuingDayOnSubmitHandler(() => () => {
                    context.addIssuingDayHandler({
                      from: time.from,
                      to: moment(value).format(),
                      id: time.id,
                      index: i
                    });
                    context.setHasChangeIssuingDayModalAlreadyDisplayed(false);
                    context.setIsAddedNewIssuingDay(false);
                  });
                } else {
                  context.addIssuingDayHandler({
                    from: time.from,
                    to: moment(value).format(),
                    id: time.id,
                    index: i
                  });
                }
                if (!context.hasChangeIssuingDayModalAlreadyDisplayed) {
                  context.setHasChangeIssuingDayModalAlreadyDisplayed(true);
                }
              }}
              KeyboardButtonProps={{
                "aria-label": "change time"
              }}
              ampm={false}
            />
            <DeleteButton
              disabled={context.formDisabled}
              onClick={(e) => {
                e.preventDefault();
                if (reservationsQuery.length > 0) {
                  context.setChangeIssuingDayModalOpened(true);
                  context.setChangeIssuingDayOnSubmitHandler(() => () => {
                    context.removeIssuingDayHandler(i);
                  });
                } else {
                  context.removeIssuingDayHandler(i);
                }
              }}
            >
              Smazat
            </DeleteButton>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </Wrap>
      {!isDateValid(time) && submitting ? (
        <>
          <ErrorMessage>
            "Výdejní termíny od - do" jsou povinná pole a musí být
            validní
          </ErrorMessage>
        </>
      ) : null}
    </>
  );
};

const isDateValid = (time: any) => !(
  time.to === null ||
  time.to === "Invalid date" ||
  time.from === null ||
  time.from === "Invalid date"
);

const DatePicker = styled(KeyboardDatePicker)`
  float: left;
  @media (max-width: 1113px) {
    margin: 15px auto;
    width: 100%;
  }
`;

const TimePicker = styled(KeyboardTimePicker)`
  width: 22%;
  margin-left: 20px;
  float: left;
  @media (max-width: 1113px) {
    margin: 15px auto;
    float: none;
    width: 100%;
  }
`;

const DeleteButton = styled.button`
  background-color: transparent;
  border: 1px solid #e32024;
  color: #e32024;
  border-radius: 4px;
  padding: 10px 30px;
  outline: none;
  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
  width: 150px;
  height: 50px;
  float: right;
  margin: 13px 0;
  &:disabled {
    color: lightgrey;
    border: 1px solid lightgrey;
  }
  @media (max-width: 1113px) {
    margin-left: 0;
    float: none;
    width: 100%;
  }
`;

const To = styled.span`
  border-bottom: 3px solid #707070;
  width: 20px;
  position: relative;
  left: 33px;
  top: -20px;
`;

const customTheme = createMuiTheme({
  overrides: {
    ["MuiPickersBasePicker" as any]: {
      pickerView: {
        backgroundColor: "#BDE6FA",
        color: "white",
        padding: "20px"
      }
    },
    ["MuiPickersDay" as any]: {
      day: {
        color: "light-gray",
        fontFamily: "OpenSansBold",
        backgroundColor: "white"
      },
      container: {
        backgroundColor: "#BDE6FA"
      },
      daySelected: {
        backgroundColor: "#0078B4",
        color: "white"
      },
      dayDisabled: {
        color: "grey"
      },
      current: {
        color: ""
      }
    }
  }
});
