import gql from "graphql-tag";
import { graphql } from "react-apollo";

const submitEFairNewProductMutation = graphql(
  gql`
    mutation SubmitEFairNewProductMutation(
      $name: String!
      $description: String!
      $price: Int!
      $amount: Int!
      $image: Upload
      $fileExt: String
      $type: String
      $photoLink: String
      $recognitionId: Int
      $eFairId: ID
    ) {
      submitEFairNewProduct(
        data: {
          name: $name
          description: $description
          price: $price
          amount: $amount
          image: $image
          fileExt: $fileExt
          type: $type
          photoLink: $photoLink
          recognitionId: $recognitionId
          eFairId: $eFairId
        }
      ) {
        id
      }
    }
  `,
  {
    name: "submitEFairNewProductMutation"
  }
);

export default submitEFairNewProductMutation;
