import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import { createMuiTheme } from "@material-ui/core/styles";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import cs from "date-fns/esm/locale/cs";
import moment from "moment";
import React, { useContext, useState } from "react";
import styled from "styled-components";

import { FormContext } from "../context/FormContext";

const TimeWrapper = styled.div`
  width: 45%;
  @media (max-width: 1113px) {
    width: 99%;
  }
`;

const Title = styled.h2`
  font-size: 1.4em;
  color: #484848;
  font-family: OpenSansBold, sans-serif;
`;
const ActionHint = styled.div`
  color: #6c6c6c;
  font-size: 0.8em;
  //clear: both;
  position: relative;
  top: -15px;
`;
const Beginning = styled.div`
  color: #6c6c6c;
  font-size: 1.2em;
  position: relative;
  top: -20px;
`;
const End = styled.div`
  color: #6c6c6c;
  font-size: 1.2em;
  position: relative;
  left: 65px;
  top: -20px;
  @media (max-width: 1113px) {
    margin-top: 30px;
    float: none;
    width: 100%;
    position: static;
  }
`;
const DatePicker = styled(KeyboardDatePicker)`
  margin-left: 1%;
  width: 100%;
`;

const ErrorWrapper = styled.div`
  margin: 20px 0 6px 0;
  width: 100%;
  padding-right: 10px;
  border: ${(props) => (props.invalid ? "1px solid rgb(228, 31, 37)" : "none")};
  border-radius: 5px;
`;
const ErrorMessage = styled.div`
  color: rgb(228, 31, 37);
  float: left;
  font-size: 0.7rem;
`;

const customTheme = createMuiTheme({
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: "#BDE6FA",
        color: "white",
        padding: "20px"
      }
    },
    MuiPickersDay: {
      day: {
        color: "light-gray",
        fontFamily: "OpenSansBold",
        backgroundColor: "white"
        // borderRadius: "px",
      },
      container: {
        backgroundColor: "#BDE6FA"
      },
      daySelected: {
        backgroundColor: "#0078B4",
        color: "white"
      },
      dayDisabled: {
        color: "grey"
      },
      current: {
        color: ""
      }
    }
  }
});

const FairDate = ({
  begins,
  setBegins,
  ends,
  setEnds,
  fromValid,
  toValid,
  submitting
}) => {
  const context = useContext(FormContext);
  const handleFairBeginsHandler = (date) => {
    setBegins(moment(date).startOf("day").format());
  };
  const handleFairEndsHandler = (date) => {
    setEnds(moment(date).endOf("day").format());
  };
  return (
    <>
      <div
        style={{
          position: "relative",
          top: "-30px"
        }}
      >
        <Title>Kdy se koná e-jarmark</Title>
        <ActionHint>
          Zadejte období, ve kterém si mohou zákazníci rezervovat výrobky.
          Rezervace bude spuštěná od půlnoci do půlnoci.
        </ActionHint>
      </div>
      <br></br>
      <Beginning>Začátek*</Beginning>
      <ThemeProvider theme={customTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
          <div
            style={{
              position: "relative",
              top: "-30px"
            }}
          >
            <Grid container justify="space-around">
              <TimeWrapper>
                <ErrorWrapper
                  className="mandatory"
                  invalid={!fromValid && submitting}
                >
                  <DatePicker
                    disabled={context.formDisabled}
                    disableToolbar
                    variant="inline"
                    format="dd.MM.yyyy"
                    margin="normal"
                    name="date"
                    id="date-picker-inline"
                    label="Datum"
                    value={begins}
                    onChange={handleFairBeginsHandler}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </ErrorWrapper>
                {!fromValid && submitting ? (
                  <ErrorMessage>
                    "Začátek" je povinné pole a musí být validní
                  </ErrorMessage>
                ) : null}
              </TimeWrapper>
              <End>Konec*</End>
              <TimeWrapper>
                <ErrorWrapper
                  className="mandatory"
                  invalid={!toValid && submitting}
                >
                  <DatePicker
                    disabled={context.formDisabled}
                    disableToolbar
                    variant="inline"
                    format="dd.MM.yyyy"
                    margin="normal"
                    name="date"
                    id="date-picker-inline"
                    label="Datum"
                    value={ends}
                    onChange={handleFairEndsHandler}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                  />
                </ErrorWrapper>
                {!toValid && submitting ? (
                  <ErrorMessage>
                    "Konec" je povinné pole a musí být validní
                  </ErrorMessage>
                ) : null}
              </TimeWrapper>
            </Grid>
          </div>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </>
  );
};

export default FairDate;
