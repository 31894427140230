import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { FormContext } from "../context/FormContext";

const Title = styled.h2`
  font-size: 1.4em;
  color: #484848;
  font-family: OpenSansBold, sans-serif;
  margin-top: 60px;
  @media (max-width: 1113px) {
    margin-top: 10px;
  }
`;
const Label = styled.label`
  color: #484848;
  margin-top: 20px;
`;
const EmailYTWrap = styled.div`
  //background-color: lawngreen;
  display: flex;
  width: 100%;
  margin-top: 30px;
  justify-content: space-between;
  @media (max-width: 1113px) {
    display: inline;
    width: 100%;
  }
`;
const YTWrap = styled.div`
  width: 45%;
  //float: right;
  //background-color: #3f51b5;
  @media (max-width: 1113px) {
    width: 100%;
    margin-top: 50px;
  }
`;
const YTLabel = styled.label`
  color: #484848;

  @media (max-width: 1113px) {
    position: static;
    width: 100%;
    float: none;
  }
`;
const YoutubeLink = styled.input`
  padding: 0.5rem 12px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  outline: 0;
  pointer-events: all;
  border-radius: 3px;
  font-weight: 200;
  display: inline-block;
  color: #313a45;
  background-color: #fff;
  background-image: none;
  border: 1px solid #929fa6;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 1px 3px 0 #b4bfc5 inset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  float: right;
  font-size: 0.8em;
  height: 30px;
  @media (max-width: 1113px) {
    width: 100%;
  }
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
`;
const ContactEmailWrap = styled.div`
  //background-color: orange;
  width: 45%;
  @media (max-width: 1113px) {
    width: 100%;
    margin: 20px auto;
  }
`;
const ContactEmail = styled.input`
  padding: 0.5rem 12px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  outline: 0;
  pointer-events: all;
  border-radius: 3px;
  font-weight: 200;
  display: inline-block;
  color: #313a45;
  background-color: #fff;
  background-image: none;
  border: 1px solid #929fa6;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 1px 3px 0 #b4bfc5 inset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  font-size: 0.8em;
  height: 30px;
  margin-bottom: 6px;
  border: ${(props) =>
    props.invalid ? "1px solid rgb(228, 31, 37)" : "1px solid grey"};
  @media (max-width: 1113px) {
    width: 100%;
  }
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
`;
const WelcomeGoalWrap = styled.div`
  //background-color: #61dafb;
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 1113px) {
    margin: 20px auto;
    display: inline;
  }
`;
const WelcomeWrap = styled.div`
  //background-color: #3f51b5;
  width: 45%;
  @media (max-width: 1113px) {
    width: 100%;
  }
`;
const Welcome = styled.textarea`
  padding: 0.5rem 12px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  outline: 0;
  pointer-events: all;
  border-radius: 3px;
  font-weight: 200;
  display: inline-block;
  color: #313a45;
  background-color: #fff;
  background-image: none;
  border: 1px solid #929fa6;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 1px 3px 0 #b4bfc5 inset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  font-size: 0.8em;
  resize: none;
  height: 120px;
  border: ${(props) =>
    props.invalid ? "1px solid rgb(228, 31, 37)" : "1px solid grey"};
  @media (max-width: 1113px) {
    width: 100%;
    float: none;
  }
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
`;
const WelcomeLength = styled.div`
  float: right;
  position: relative;
  top: 140px;
  right: 7px;
  font-size: 0.8em;
`;
const GoalLength = styled.div`
  float: right;
  position: relative;
  top: 162px;
  z-index: 2;
  right: 7px;
  font-size: 0.8em;
`;
const GoalWrap = styled.div`
  width: 45%;
  @media (max-width: 1113px) {
    width: 100%;
    margin-top: 30px;
  }
`;
const Goal = styled.textarea`
  padding: 0.5rem 12px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  outline: 0;
  pointer-events: all;
  border-radius: 3px;
  font-weight: 200;
  display: inline-block;
  color: #313a45;
  background-color: #fff;
  background-image: none;
  border: 1px solid #929fa6;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 1px 3px 0 #b4bfc5 inset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  margin-bottom: 5px;
  resize: none;
  position: relative;
  float: right;
  height: 120px;
  top: 18px;
  z-index: 1;
  font-size: 0.8em;
  border: ${(props) =>
    props.invalid ? "1px solid rgb(228, 31, 37)" : "1px solid grey"};
  @media (max-width: 1113px) {
    margin-bottom: 1px;
    width: 100%;
    float: none;
  }
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
`;
const GoalLabel = styled.label`
  color: #484848;
  @media (max-width: 1113px) {
    position: static;
    float: none;
  }
`;
const ErrorMessage = styled.div`
  color: rgb(228, 31, 37);
  font-size: 0.7rem;
`;
const GoalErrorMessage = styled.div`
  color: rgb(228, 31, 37);
  font-size: 0.7rem;
  position: relative;
  top: 19px;
  @media (max-width: 1113px) {
    position: static;
    width: 100%;
    float: none;
  }
`;
const Hint = styled.div`
  color: #6c6c6c;
  font-size: 0.8em;
  display: inline-block;
  position: relative;
  top: -15px;
`;

const GeneralInfo = ({
  youtubeLink,
  welcomeText,
  fairGoal,
  setYoutubeLink,
  setWelcomeText,
  setFairGoal,
  contactEmail,
  setContactEmail,
  introTextValid,
  goalValid,
  contactEmailValid,
  submitting
}) => {
  const context = useContext(FormContext);
  const [welcomeLength, setWelcomeLength] = useState(0);
  const [goalLength, setGoalLength] = useState(0);
  useEffect(() => {
    setWelcomeLength(welcomeText.length);
    setGoalLength(fairGoal.length);
  }, [welcomeText, fairGoal]);
  return (
    <>
      <Title>O našem podnikání</Title>
      <Hint>
        Představte svou třídu a své podnikání, přidejte fotky, videopozvánku...
        Je to na Vás :)
      </Hint>
      <WelcomeGoalWrap>
        <WelcomeWrap>
          <Label htmlFor="welcome">O nás*</Label>
          <br></br>
          <WelcomeLength>{`${welcomeLength}/100`}</WelcomeLength>
          <Welcome
            className="mandatory"
            invalid={!introTextValid && submitting}
            maxLength="100"
            disabled={context.formDisabled}
            name="welcome"
            cols="20"
            rows="5"
            placeholder="Představte krátce zákazníkům svou třídu a svou firmu (max. 100 znaků). Např.: Jsme firma Šikulové a vyrábíme ty nej dárečky, které si dovedete představit &#128522; Těšíme se na Váš nákup!"
            value={welcomeText}
            onChange={(e) => {
              setWelcomeLength(welcomeText.length);
              setWelcomeText(e.target.value);
            }}
          />
          {!introTextValid && submitting ? (
            <ErrorMessage>"O nás" je povinné pole</ErrorMessage>
          ) : null}
        </WelcomeWrap>
        <GoalWrap>
          <GoalLabel htmlFor="goal">Náš cíl*</GoalLabel>
          <GoalLength>{`${goalLength}/250`}</GoalLength>
          <Goal
            className="mandatory"
            invalid={!goalValid && submitting}
            maxLength="250"
            disabled={context.formDisabled}
            name="goal"
            placeholder="Dejte zákazníkům vědět, co plánujete udělat s výdělkem z e-jarmarku (max. 250 znaků). Např.: Chceme nejdřív splatit základní kapitál a ze zbytku peněz si užít výlet na Safari ve Dvoře Králové. Rádi bychom také podpořili místní psí útulek."
            cols="20"
            rows="5"
            value={fairGoal}
            onChange={(e) => {
              setGoalLength(fairGoal.length);
              setFairGoal(e.target.value);
            }}
          />
          {!goalValid && submitting ? (
            <GoalErrorMessage>"Náš cíl" je povinné pole</GoalErrorMessage>
          ) : null}
          <br></br>
        </GoalWrap>
      </WelcomeGoalWrap>
      <EmailYTWrap>
        <ContactEmailWrap>
          <Label htmlFor="contactEmail">Kontaktní e-mail pro zákazníky*</Label>
          <ContactEmail
            className="mandatory"
            invalid={!contactEmailValid && submitting}
            disabled={context.formDisabled}
            type="text"
            placeholder="Email se zobrazí zákazníkům"
            value={contactEmail}
            name="contactEmail"
            onChange={(e) => setContactEmail(e.target.value)}
          />
          {!contactEmailValid && submitting ? (
            <ErrorMessage>
              "Kontaktní e-mail" je povinné pole a musí mít platný formát
            </ErrorMessage>
          ) : null}
        </ContactEmailWrap>
        <YTWrap>
          <YTLabel htmlFor="youtube">Odkaz na video na YouTube</YTLabel>
          <YoutubeLink
            disabled={context.formDisabled}
            type="text"
            placeholder="Natočili jste upoutávku na e-jarmark? Vložte odkaz na video a nalákejte zákazníky!"
            value={youtubeLink}
            name="youtube"
            onChange={(e) => setYoutubeLink(e.target.value)}
          />
        </YTWrap>
      </EmailYTWrap>
    </>
  );
};

export default GeneralInfo;
