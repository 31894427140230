import React from "react";
import styled from "styled-components";

const CardWrapper = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 3px 1px rgba(95, 112, 117, 0.75);
  width: 300px;
  height: 530px;
  text-align: left;
  overflow-wrap: break-word;
  cursor: pointer;
  background-color: white;
  flex: 0 0 300px;
  box-sizing: border-box;
  margin: 1rem 0.5em;
  transition: 0.3s;
  &:hover {
    transform: scale(1.01);
  }
  @media (max-width: 665px) {
    flex: auto;
  }
`;
const Image = styled.div`
  height: 300px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${(props) =>
    props.src ? process.env.REACT_APP_URL + props.src : null});
  border-radius: 4px 4px 0 0;
`;
const Name = styled.div`
  margin: 25px 0.9375rem 1rem 0.9375rem;
  font-weight: bolder;
  font-family: OpenSansBold, sans-serif;
  height: 56px;
`;
const Description = styled.div`
  font-size: 0.8em;
  margin: 0 0.9375rem 0 0.9375rem;
  height: 80px;
  /* background-color: green; */
`;
const Price = styled.div`
  margin-top: 10px;
  margin-left: 0.9375rem;
  font-size: 1.2em;  
`;
const SoldOut = styled.p`
  color: orangered;
  font-weight: bold;
  margin: 0 0.9375rem 0 0.9375rem;
  float: right;
  padding: 10px;
`;
const Button = styled.button`
  color: #fff !important;
  background-color: #1279b2;
  border-top-color: #1279b2;
  border-bottom-color: #072e62;
  box-shadow: inset 0 -2px 0 0 #072e62;
  outline: 0;
  //background-color: #0078b4;
  //margin: 20px 0.9375rem 30px 20px;
  margin: 0 0.9375rem 0 0.9375rem;
  width: 140px;
  height: 40px;
  //color: white;
  float: right;
  border: none;
  border-radius: 4px;
  outline: none;
  &:hover {
    border-bottom-color: #1279b2;
    box-shadow: inset 0 -2px 0 0 #1279b2;
  }
  &:disabled {
    background-color: #bbbbbb;
    border-bottom-color: #a6a6a6;
    box-shadow: inset 0 -2px 0 0 #a6a6a6;
  }
`;

const ProductCard = (props) => {
  const { product, isActive, setModalIsOpened, setProduct } = props;
  return (
    <>
      <CardWrapper
        id={`id_product_${product.id}`}
        onClick={() => {
          if (isActive) {
            setModalIsOpened(true);
            setProduct(product);
          }
        }}
      >
        {product.photoLinkPublic ? (
          <Image src={`/api/files/${product.photoLinkPublic}`} />
        ) : null}
        <Name>{product.namePublic}</Name>
        <Description>{product.descriptionPublic}</Description>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Price>
            <b>{product.pricePublic}</b> Kč
          </Price>
          {product.amountPublic === 0 ? (
            <SoldOut>Vyprodáno</SoldOut>
          ) : (
            <Button
              disabled={!isActive}
              onClick={() => {
                setModalIsOpened(true);
                setProduct(product);
              }}
            >
              Rezervovat
            </Button>
          )}
        </div>
      </CardWrapper>
    </>
  );
};

export default ProductCard;
