import gql from "graphql-tag";
import { graphql } from "react-apollo";

const submitEFairMutation = graphql(
  gql`
    mutation SubmitEFairMutation(
      $id: ID!
      $semester: Int
      $state: String
      $approval: String
      $approvalText: String
      $videoLink: String
      $contactEmail: String
      $introText: String
      $goal: String
      $from: DateTime
      $to: DateTime
      $consent: Boolean
      $issuingTimeChangeReason: String
      $products: [UpdateProductInput]
      $newProducts: [CreateProductInput]
      $streetAndNumber: String
      $city: String
      $zipCode: Int
      $note: String
      $issuingDays: [UpdateOrCreateIssueTimesInput]
      $imagesToRemove: [ID]
      $managerNote: String
    ) {
      submitEFair(
        data: {
          id: $id
          semester: $semester
          state: $state
          approval: $approval
          approvalText: $approvalText
          videoLink: $videoLink
          contactEmail: $contactEmail
          introText: $introText
          goal: $goal
          from: $from
          to: $to
          consent: $consent
          issuingTimeChangeReason: $issuingTimeChangeReason
          products: $products
          newProducts: $newProducts
          streetAndNumber: $streetAndNumber
          city: $city
          zipCode: $zipCode
          note: $note
          issuingDays: $issuingDays
          imagesToRemove: $imagesToRemove
          managerNote: $managerNote
        }
      ) {
        id
        semester
        state
        approval
        approvalText
        videoLink
        contactEmail
        introText
        goal
        from
        to
        consent
        issuingTimeChangeReason
        managerNote
        issuingPlace {
          id
          streetAndNumber
          city
          zipCode
          note
        }
        products {
          photoLink
          name
          description
          price
          amount
        }
        issuingTime {
          id
          from
          to
        }
      }
    }
  `,
  {
    name: "submitEFairMutation"
  }
);

export default submitEFairMutation;
