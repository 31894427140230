import withStyles from "@material-ui/core/styles/withStyles";
import gql from "graphql-tag";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { graphql } from "react-apollo";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import Reaptcha from 'reaptcha';

const styles = (theme) => ({
  errorMessage: {
    backgroundColor: theme.palette.error.dark,
    margin: "0 auto",
    width: "300px"
  }
});
const Wrap = styled.div`
  width: 100%;
  margin: auto auto;
  height: 100%;
  //background-color: #3f51b5;
  @media (max-width: 530px) {
    width: 90%;
    background-color: transparent;
    box-shadow: none;
    top: -50px;
  }
`;
const Form = styled.form`
  border-radius: 4px;
  text-align: center;
  padding: 30px;
  width: 500px;
  background-color: white;
  height: auto;
  margin: 25px auto;
  box-shadow: 1px 1px 5px 1px grey;
  @media (max-width: 530px) {
    width: 90%;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    top: -50px;
  }
`;
const Input = styled.input`
  padding: 0.5rem 12px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  outline: 0;
  pointer-events: all;
  border-radius: 3px;
  font-weight: 200;
  display: inline-block;
  color: #313a45;
  background-color: #fff;
  background-image: none;
  border: 1px solid #929fa6;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 1px 3px 0 #b4bfc5 inset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  margin: 8px 0;
  @media (max-width: 530px) {
    background-color: white;
  }
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
`;
const Label = styled.label`
  font-weight: bold;
  float: left;
  @media (max-width: 530px) {
    font-size: 12px;
  }
`;

const SubmitButton = styled.button`
  color: #fff;
  background-color: #ff6129;
  &:hover {
    background-color: #fa4a11;
  }
  outline: 0;
  font-size: 1.1em;
  border-radius: 4px;
  padding: 14px 20px;
  margin: 30px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  @media (max-width: 530px) {
    padding: 8px 15px;
  }
`;

const LoginForm = ({ classes, loginEFairMutation, history: { push } }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verified, setVerified] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const recaptchaSuccess = (token) => {
    setVerified(true);
  }
  return (
    <>
      <Wrap>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            loginEFairMutation({
              variables: {
                email: email,
                password: password
              }
            })
              .then((res) => {
                localStorage.setItem("token", res.data.loginEFair.token);
                push("/sprava");
              })
              .catch((e) => {
                setVerified(false);
                enqueueSnackbar("Přihlášení se nezdařilo", {
                  variant: "error",
                  autoHideDuration: 4000,
                  anchorOrigin: {
                    horizontal: "center",
                    vertical: "top"
                  }
                });
              });
          }}
        >
          <Label>E-mail</Label>
          <Input
            value={email}
            name="email"
            type="text"
            autoComplete="username"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="Zadejte e-mail"
          />
          <Label>Heslo</Label>
          <Input
            value={password}
            name="password"
            type="password"
            autoComplete="current-password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            placeholder="Zadejte heslo"
          />
          <Reaptcha sitekey="6LeK_nQlAAAAACW4_WP8tIoZcHQp6g8gugldg28v" onVerify={recaptchaSuccess} />
          <SubmitButton disabled={!verified} type="submit">Přihlásit se</SubmitButton>
          <Link to="/zapomenute-heslo">Zapomněl/a jste heslo?</Link>
        </Form>
      </Wrap>
    </>
  );
};

const loginEFairMutation = graphql(
  gql`
    mutation LoginEFairMutation($email: String!, $password: String!) {
      loginEFair(data: { email: $email, password: $password }) {
        token
        user {
          id
          email
        }
        eFair
      }
    }
  `,
  {
    name: "loginEFairMutation"
  }
);

export default withRouter(withStyles(styles)(loginEFairMutation(LoginForm)));
