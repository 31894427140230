import React from "react";

import ReservationsBody from "../components/ReservationsBody";

const ReservationsList = ({ match }) => {
  return (
    <>
      <ReservationsBody eFair={{ id: match.params.fairId }} />
    </>
  );
};

export default ReservationsList;
