import gql from "graphql-tag";
import React from "react";
import { graphql } from "react-apollo";
import { Link } from "react-router-dom";
import styled from "styled-components";

const TitleSection = styled.div`
  width: 100%;
  //margin: auto;
  padding: 10px 0;
  color: #fff;
  //background-color: white;
  //@media (max-width: 1113px) {
  //  height: 120px;
  //}
  
  @media print {
    margin-top: -40px;
    padding: 0;
  }
`;

const HeadTitle = styled.div`
  max-width: 1200px;
  width: 90%;
  margin: 5px auto;
  font-size: 2em;
  font-weight: bold;
  word-wrap: break-word;
  //background-color: #3f51b5;
  @media (max-width: 1113px) {
    display: block;
    text-align: center;
  }
`;
const Nav = styled.div`
  font-size: 1.1em;
  max-width: 1200px;
  width: 90%;
  margin: 8px auto;
  @media (max-width: 1113px) {
    width: 100%;
    text-align: center;
    margin: 10px auto;
    font-size: 0.8em;
  }

  @media print {
    display: none;
  }
`;
const ReservationsLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  padding: 5px 0px;
  border-bottom: ${(props) => (props.active ? "2px solid #fff" : "none")};
  &:hover {
    border-bottom: 3px solid #fff;
    padding: 5px 0px;
    color: #fff;
  }
  @media (max-width: 1113px) {
    &:hover {
      padding: 0;
    }
    width: 90%;
    padding: 0;
    margin-top: 8px;
    float: none;
  }
`;

const AdminTitleSection = ({
  adminPage,
  // eFairId,
  eFairQuery: { eFair = {}, ...eFairQuery }
}) => {
  return (
    <>
      <TitleSection>
          <HeadTitle>{`${
            !!eFair && !!eFair.classroom ? eFair.classroom.classroomName : "-"
          } ${
            !!eFair && !!eFair.classroom && !!eFair.classroom.school
              ? eFair.classroom.school.name
              : "-"
          }`}
          </HeadTitle>

          <Nav>
            <ReservationsLink
              to={`/sprava/${eFair.id ? eFair.id : null}/form`}
              active={adminPage === "form" ? "true" : null}
            >
              Správa e-jarmarku
            </ReservationsLink>
            <span
              style={{
                width: "30px",
                height: "20px",
                display: "inline-block"
              }}
            >
            </span>
            <ReservationsLink
              to={`/sprava/${eFair.id ? eFair.id : null}/rezervace`}
              active={adminPage === "rezervace" ? "true" : null}
            >
              Seznam rezervací
            </ReservationsLink>
          </Nav>
      </TitleSection>
    </>
  );
};

const eFairQuery = graphql(
  gql`
    query EFair($id: ID!) {
      eFair(id: $id) {
        id
        classroom {
          id
          classroomName
          school {
            id
            name
          }
        }
      }
    }
  `,
  {
    name: "eFairQuery",
    options: (props) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: props.eFairId
      }
    })
  }
);

export default eFairQuery(AdminTitleSection);
