import "./App.css";

import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import { SnackbarProvider } from "notistack";
import React, { useEffect, useState } from "react";
import { ApolloProvider } from "react-apollo";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";

import Authenticated from "./components/Authenticated.component";
import Layout from "./components/Layout.component";
import initApolloClient from "./initApolloClient";
import FairDetail from "./screens/FairDetail";
import FairsFilter from "./screens/FairsFilter";
import ForgotPassword from "./screens/ForgotPassword";
import Intro from "./screens/Intro";
import Login from "./screens/Login";
import NotFoundPage from "./screens/NotFoundPage";

const App = () => {
  const [client, setClient] = useState(null);

  useEffect(() => {
    moment.locale("cs");
    setClient(initApolloClient());
  }, []);

  if (!client) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <SimpleReactLightbox>
      <ApolloProvider client={client}>
        <SnackbarProvider maxSnack={3}>
          <Router>
            <Layout>
              <Switch>
                <Route exact path="/" component={Intro} />
                <Route exact path="/hledat" component={FairsFilter} />
                <Route exact path="/login" component={Login} />
                <Route path="/sprava" component={Authenticated} />
                <Route exact path="/detail/:fairId" component={FairDetail} />
                <Route
                  exact
                  path="/zapomenute-heslo"
                  component={ForgotPassword}
                />
                <Route path="*" exact component={NotFoundPage} />
              </Switch>
            </Layout>
          </Router>
        </SnackbarProvider>
      </ApolloProvider>
    </SimpleReactLightbox>
  );
};

export default App;
