import gql from "graphql-tag";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { graphql } from "react-apollo";
import styled from "styled-components";

import children from "../assets/images/obrazek.png";
import background from "../assets/images/pozadi1.png";

const GeneralInfoPublic = ({ eFairQuery: { eFair = {}, ...eFairQuery } }) => {
  const [schoolTitle, setSchoolTitle] = useState("");

  useEffect(() => {
    if (!!eFair && !!eFair.classroom && !!eFair.classroom.school) {
      setSchoolTitle(
        `${eFair.classroom.classroomName} ${eFair.classroom.school.name}`
      );
    }
  }, [eFair]);

  return (
    <>
      <Wrap>
        <MaxWidthWrap>
          <TitleSection>
            <HeadTitle>E-jarmark</HeadTitle>
            <SchoolName>{schoolTitle}</SchoolName>
          </TitleSection>
          <Welcome>{eFair ? eFair.introText : ""}</Welcome>
          <FlexWrap>
            <Column>
              <h3>Náš cíl</h3>
              <p>{eFair ? eFair.goal : ""}</p>
            </Column>
            <Column>
              <Children src={children} />
              <h3>Konání e-jarmarku</h3>
              {(!!eFair && moment(Date.now()).isAfter(eFair.to)) ||
              eFair.state === "Ukončený"
                ? (
                  <FairDate>E-jarmark již proběhl</FairDate>
                ) : (
                  <>
                    <FairDate>{`${moment(eFair.from).format(
                    "DD. MM. YYYY"
                  )} \u2013 ${moment(eFair.to).format(
                    "DD. MM. YYYY"
                  )}`}
                    </FairDate>
                    <p>V tomto období si můžete rezervovat naše výrobky</p>
                  </>
                )}
            </Column>
          </FlexWrap>
          <br></br>
          <ContactEmail>
            Pokud máte nějaký dotaz týkající se výrobků nebo organizačních věcí,
            můžete se zeptat na této e-mailové adrese:
            <div
              style={{
                width: "100%",
                textAlign: "center",
                margin: "0 auto",
                fontFamily: "OpenSansBold, sans-serif",
              }}
            >
              {eFair.contactEmail}
            </div>
          </ContactEmail>
        </MaxWidthWrap>
      </Wrap>
    </>
  );
};

const eFairQuery = graphql(
  gql`
    query EFair($id: ID!) {
      eFair(id: $id) {
        id
        goalPublic
        goal
        introTextPublic
        introText
        contactEmail
        fromPublic
        toPublic
        from
        to
        classroom {
          id
          classroomName
          school {
            id
            name
          }
        }
      }
    }
  `,
  {
    name: "eFairQuery",
    options: (props) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: props.eFairId
      }
    })
  }
);

export default eFairQuery(GeneralInfoPublic);

const Wrap = styled.div`
  // background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  //box-shadow: 1px 1px 10px 1px grey;
`;

const Children = styled.img`
  width: 18rem;
  height: auto;
  position: absolute;
  top: -100px;
  right: 0;
  z-index: 1;
  @media (max-width: 900px) {
    display: none;
  }
`;

const MaxWidthWrap = styled.div`
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
`;

const TitleSection = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: auto;
  height: auto;
  color: #484848;
  z-index: 2;
  text-align: center;
`;

const HeadTitle = styled.div`
  color: #fff;
  font-size: 1em;
  margin-top: 15px;
  margin-bottom: 10px;
  font-weight: lighter;
`;

const SchoolName = styled.div`
  font-size: 2em;
  font-family: OpenSansBold, sans-serif;
  margin-bottom: 10px;
  color: #fff;
`;

const Welcome = styled.h2`
  margin: 10px auto;
  width: 60%;
  text-align: center;
  font-weight: lighter;
  color: #fff;
  @media (max-width: 530px) {
    width: 100%;
    margin: 10px 0 10px 0;
    font-size: 18px;
  }
`;

const FlexWrap = styled.div`
  padding: 10px 0;
  display: flex;
  margin: 0 auto;
  width: 100%;
  @media (max-width: 570px) {
    float: left;
    flex-flow: wrap;
    justify-content: center;
  }
`;

const Column = styled.div`
  flex: 50%;
  padding: 10px 50px;
  position: relative;
  height: auto;
  margin: 12px;
  background-color: white;
  color: #484848;
  @media (max-width: 850px) {
    float: left;
    font-size: 13px;
    padding: 10px 15px;
  }
`;

const FairDate = styled.p`
  margin: 15px 0;
  font-weight: bold;
  font-family: OpenSansBold, sans-serif;
  font-size: 1.5em;
`;

const ContactEmail = styled.div`
  width: 80%;
  text-align: center;
  position: relative;
  margin: 0 auto 70px auto;
  color: #fff;
  @media (max-width: 1113px) {
    //margin-top: 30px;
    clear: both;
    width: 80%;
  }
  @media (max-width: 1165px) {
    margin-top: 30px;
  }
`;
