import withStyles from "@material-ui/core/styles/withStyles";
import imageCompression from "browser-image-compression";
import { DropzoneArea } from "material-ui-dropzone";
import { useSnackbar } from "notistack";
import React, { FC, useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { FormContext } from "../context/FormContext";

interface Props {
  product: any;
  index: number;
  classes: Record<string, any>;
  submitting: boolean;
}

const ProductCardFormComponent: FC<Props> = ({ product, index, classes, submitting /* recogId, setRecogId */ }) => {
  const { enqueueSnackbar } = useSnackbar();
  const context = useContext(FormContext);
  const [descriptionLength, setDescriptionLength] = useState(0);

  useEffect(() => {
    setDescriptionLength(product.description.length);
  }, [product.description]);

  return (
    <Wrapper deleted={product.delete}>
      {product.photoLink ? (
        <div style={{ position: "relative" }}>
          <DeleteImageButton
            disabled={context.formDisabled}
            onClick={(e) => {
              e.preventDefault();
              const newProductData = {
                index,
                id: product.id,
                name: product.name,
                description: product.description,
                amount: product.amount,
                price: product.price,
                recognitionId: product.recognitionId,
                image: null,
                photoLink: null
              };
              context.updateProductHandler(newProductData);
            }}
          >
            x
          </DeleteImageButton>
          <Image src={`/api/files/${product.photoLink}`} />
        </div>
      ) : (
        <>
          <DropzoneArea
            dropzoneProps={{ disabled: context.formDisabled }}
            acceptedFiles={["image/*"]}
            dropzoneText="Obrázek přetáhněte sem nebo kliknutím vyberte*"
            onChange={(files) => {
              const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
              };
              if (files.length === 1) {
                imageCompression(files[0], options)
                  .then((compressedFile) => {
                    const newProductData = {
                      index,
                      id: product.id,
                      name: product.name,
                      description: product.description,
                      amount: product.amount,
                      recognitionId: product.recognitionId,
                      price: product.price,
                      image: compressedFile || null,
                      photoLink: null
                    };
                    context.updateProductHandler(newProductData);
                  })
                  .catch((error) => {
                    console.log(error.message);
                  });
              }
            }}
            showAlerts={false}
            onDrop={(e) => {
              e.forEach((item) =>
                enqueueSnackbar(`Soubor ${item.name} připraven.`, {
                  variant: "success",
                  autoHideDuration: 4000
                })
              );
            }}
            onDelete={(e) => {
              enqueueSnackbar(`Soubor ${e.name} odstraněn.`, {
                variant: "success",
                autoHideDuration: 4000
              });
            }}
            dropzoneParagraphClass={classes.dropzoneParagraph}
            filesLimit={1}
            maxFileSize={10000000}
          />
        </>
      )}
      {(product.image === null || product.image?.length === 0) &&
      product.delete !== true &&
      submitting
        ? (
          <ErrorMessage className="mandatory" style={{ margin: "10px" }}>
            "Obrázek" je povinné pole
          </ErrorMessage>
        ) : null}
      <FormWrap>
        <Label htmlFor="name">Název*</Label>
        <Name
          className="mandatory"
          invalid={product.name === "" && product.delete !== true && submitting}
          id="name"
          name="name"
          value={product.name}
          type="text"
          disabled={context.formDisabled}
          onChange={(e) => {
            const newProductData = {
              index,
              name: e.target.value,
              description: product.description,
              amount: product.amount,
              price: product.price,
              recognitionId: product.recognitionId,
              image: product.image,
              id: product.id,
              photoLink: product.photoLink
            };
            context.updateProductHandler(newProductData);
          }}
        />
        {product.name === "" && product.delete !== true && submitting ? (
          <ErrorMessage>"Název produktu" je povinné pole</ErrorMessage>
        ) : null}
        <div
          style={{
            height: "15px"
          }}
        >
        </div>
        <Label htmlFor="description">Popis*</Label>
        <div
          style={{
            position: "relative",
            top: "-15px"
          }}
        >
          <DescriptionLength>{`${descriptionLength}/125`}</DescriptionLength>
          <Description
            className="mandatory"
            invalid={product.description === "" &&
              product.delete !== true &&
              submitting}
            maxLength={125}
            id="description"
            name="description"
            value={product.description}
            // type="text" textarea
            disabled={context.formDisabled}
            onChange={(e) => {
              setDescriptionLength(product.description.length);
              const newProductData = {
                index,
                name: product.name,
                description: e.target.value,
                amount: product.amount,
                recognitionId: product.recognitionId,
                price: product.price,
                image: product.image,
                id: product.id,
                photoLink: product.photoLink
              };
              context.updateProductHandler(newProductData);
            }}
          />
          {product.description === "" &&
          product.delete !== true &&
          submitting
            ? <ErrorMessage>"Popis produktu" je povinné pole</ErrorMessage>
            : null}
        </div>
        <PriceWrap>
          <Label htmlFor="price">Cena*</Label>
          <Currency>
            <Price
              className="mandatory"
              invalid={product.price === "" && product.delete !== true && submitting}
              id="price"
              name="price"
              pattern="[0-9]*"
              value={product.price}
              type="text"
              disabled={context.formDisabled}
              onChange={(e) => {
                const price = e.target.validity.valid
                  ? e.target.value
                  : product.price;
                context.updateProductHandler({
                  index,
                  name: product.name,
                  description: product.description,
                  amount: product.amount,
                  price: price,
                  recognitionId: product.recognitionId,
                  image: product.image,
                  id: product.id,
                  photoLink: product.photoLink
                });
              }}
            />
            ,-Kč
          </Currency>
          {product.price === "" && product.delete !== true && submitting ? (
            <ErrorMessage>"Cena" je povinné pole</ErrorMessage>
          ) : null}
        </PriceWrap>
        <AmountWrap>
          <AmountLabel htmlFor="amount">Množství*</AmountLabel>
          <Amount
            className="mandatory"
            invalid={product.amount === "" && product.delete !== true && submitting}
            id="amount"
            name="amount"
            pattern="[0-9]*"
            value={product.amount}
            type="text"
            disabled={context.formDisabled}
            onChange={(e) => {
              const amount = e.target.validity.valid
                ? e.target.value
                : product.amount;
              context.updateProductHandler({
                index,
                name: product.name,
                recognitionId: product.recognitionId,
                description: product.description,
                amount: amount,
                price: product.price,
                image: product.image,
                id: product.id,
                photoLink: product.photoLink
              });
            }}
          />

          {product.amount === "" && product.delete !== true && submitting ? (
            <ErrorMessage>"Množství" je povinné pole</ErrorMessage>
          ) : null}
        </AmountWrap>
        {product.delete ? (
          <DeleteButton
            invalid={product.amount === "" && product.delete !== true && submitting}
            disabled={context.formDisabled}
            onClick={(e) => {
              e.preventDefault();
              context.recoverProductHandler({ index });
            }}
          >
            Obnovit
          </DeleteButton>
        ) : (
          <DeleteButton
            invalid={product.amount === "" && product.delete !== true && submitting}
            disabled={context.formDisabled}
            onClick={(e) => {
              e.preventDefault();
              context.removeProductHandler({
                index,
                id: product.id
              });
            }}
          >
            Smazat
          </DeleteButton>
        )}
        {product.recognitionId ? <Identifier>č.{product.recognitionId}</Identifier> : null}
      </FormWrap>
    </Wrapper>
  );
};

ProductCardFormComponent.propTypes = {};

const styles = (theme) => ({
  paper: {
    alignSelf: "flex-start",
    width: "200px"
  },
  dropzoneParagraph: {
    height: "50px",
    fontFamily: theme.typography.fontFamily
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    width: "100%",
    height: "100%"
  }
} as const);

export default withStyles(styles)(ProductCardFormComponent);

const Wrapper = styled.div<{ deleted?: boolean }>`
  margin: 0% 10px 5% 10px;
  width: 350px;
  border-radius: 4px;
  background-color: white;
  flex-direction: row;
  height: auto;
  box-shadow: 1px 1px 5px 1px grey;
  opacity: ${({ deleted }) => (deleted ? 0.2 : 1)};
`;
const Label = styled.label`
  font-size: 0.9em;
  display: table;
`;
const Name = styled.input<{ invalid?: boolean }>`
  padding: 0.5rem 12px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  outline: 0;
  pointer-events: all;
  border-radius: 3px;
  font-weight: 200;
  display: inline-block;
  color: #313a45;
  background-color: #fff;
  background-image: none;
  border: 1px solid #929fa6;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 1px 3px 0 #b4bfc5 inset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  margin: 8px 0;
  border: ${(props) =>
    props.invalid ? "1px solid rgb(228, 31, 37)" : "1px solid #929fa6"};
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
`;
const PriceWrap = styled.div`
  width: 49%;
  clear: both;
  float: left;
`;
const Price = styled.input<{ invalid?: boolean }>`
  padding: 0.5rem 12px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  outline: 0;
  pointer-events: all;
  border-radius: 3px;
  font-weight: 200;
  display: inline-block;
  color: #313a45;
  background-color: #fff;
  background-image: none;
  border: 1px solid #929fa6;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 1px 3px 0 #b4bfc5 inset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: right;
  width: 80%;
  margin: 8px 0;
  border: ${(props) =>
    props.invalid ? "1px solid rgb(228, 31, 37)" : "1px solid #929fa6"};
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
`;
const AmountWrap = styled.span`
  width: 49%;
  float: right;
`;
const AmountLabel = styled.label`
  font-size: 0.9em;
  display: table;
`;
const Amount = styled.input<{ invalid?: boolean }>`
  padding: 0.5rem 12px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  outline: 0;
  pointer-events: all;
  border-radius: 3px;
  font-weight: 200;
  display: inline-block;
  color: #313a45;
  background-color: #fff;
  background-image: none;
  border: 1px solid #929fa6;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 1px 3px 0 #b4bfc5 inset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  margin: 8px 0;
  text-align: right;
  border: ${(props) =>
    props.invalid ? "1px solid rgb(228, 31, 37)" : "1px solid #929fa6"};
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
`;
const DeleteButton = styled.button<{ invalid?: boolean }>`
  background-color: transparent;
  border: 1px solid #e32024;
  color: #e32024;
  border-radius: 4px;
  padding: 10px 12px;
  outline: none;
  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
  width: 120px;
  height: 40px;
  float: right;
  margin: 30px 0 10px 0;
  clear: both;
  &:disabled {
    background-color: lightgrey;
    color: white;
    border: none;
  }
`;
const Description = styled.textarea<{ invalid?: boolean }>`
  padding: 0.5rem 12px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  outline: 0;
  pointer-events: all;
  border-radius: 3px;
  font-weight: 200;
  display: inline-block;
  color: #313a45;
  background-color: #fff;
  background-image: none;
  border: 1px solid #929fa6;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 1px 3px 0 #b4bfc5 inset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  margin: 8px 0;
  resize: none;
  border: ${(props) =>
    props.invalid ? "1px solid rgb(228, 31, 37)" : "1px solid #929fa6"};
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
`;
const DescriptionLength = styled.div`
  float: right;
  position: relative;
  top: 82px;
  z-index: 2;
  right: 7px;
  font-size: 0.8em;
`;
const Image = styled.div<{ src?: string }>`
  width: 100%;
  height: 250px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${(props) =>
    props.src ? process.env.REACT_APP_URL + props.src : null});
`;
const FormWrap = styled.div`
  padding: 10px;
`;
const Currency = styled.span``;
const DeleteImageButton = styled.button`
  background-color: #e32024;
  color: white;
  border-radius: 4px;
  position: absolute;
  right: 5px;
  padding: 4px 9px;
  border: none;
  top: 5px;
  &:hover {
    background-color: #ff3300;
  }
  &:disabled {
    background-color: lightgrey;
  }
`;
const ErrorMessage = styled.div`
  color: rgb(228, 31, 37);
  font-size: 0.7rem;
  margin-bottom: 5px;
  width: 100%;
`;
const Identifier = styled.div`
/* background-color: blue; */
position: relative;
top: 50px;
font-size: 1.1em;
font-weight: bold;
height: 0px;
`;
