import withStyles from "@material-ui/core/styles/withStyles";
import { DropzoneArea } from "material-ui-dropzone";
import { useSnackbar } from "notistack";
import React, { FC, useContext } from "react";
import styled from "styled-components";

import openInNew from "../assets/images/open_in_new-24px.svg";
import { FormContext } from "../context/FormContext";

interface Props {
  checked?: boolean;
  setChecked: (c: boolean) => unknown;
  isMandatory?: boolean;
  submitting?: boolean;
}

const PhotoUpload: FC<Props & { classes: any }> = ({
  classes,
  checked,
  setChecked,
  isMandatory,
  submitting
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const context = useContext(FormContext);
  return (
    <Wrap>
      <br></br>
      <Title>Fotky z průběhu projektu a výroby</Title>
      <Checkbox
        disabled={context.formDisabled}
        type="checkbox"
        id="conditions"
        checked={checked}
        onChange={() => setChecked(!checked)}
      />

      <Consent>
        <span onClick={() => setChecked(!checked)}>
          *Seznámil/a jsem se s pravidly nahrávání fotografií a videa do e-jarmarku, více{" "}
        </span>
        <a
          href="https://www.csas.cz/content/dam/cz/csas/www_csas_cz/dokumenty/abecedapenez/souhlas_spravce.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          zde
          <img
            style={{
              marginLeft: "5px"
            }}
            src={openInNew}
          />
        </a>
      </Consent>
      {submitting && !checked && isMandatory ? (
        <ErrorMessage className="mandatory">
          Pokud nahráváte média, musíte souhlasit s podmínkami jejich nahrávání
        </ErrorMessage>
      ) : null}
      <UploaderWrap>
        {!context.formDisabled ? (
          <>
            <DropzoneArea
              acceptedFiles={["image/*"]}
              dropzoneText="Přetáhněte sem obrázek nebo klikněte pro výběr z počítače"
              onChange={(files) => {
                context.addImagesHandler(files);
              }}
              showAlerts={true}
              onDrop={(e) => {
                e.forEach((item) =>
                  enqueueSnackbar(`Soubor ${item.name} připraven.`, {
                    variant: "success",
                    autoHideDuration: 4000
                  })
                );
              }}
              onDelete={(e) => {
                enqueueSnackbar(`Soubor ${e.name} odstraněn.`, {
                  variant: "success",
                  autoHideDuration: 4000
                });
              }}
              dropzoneParagraphClass={classes.dropzoneParagraph}
              filesLimit={30}
              maxFileSize={1000000}
              getDropRejectMessage={(rejectedFile) => `Je možné nahrát obrázek o maximální velikost 1MB, váš soubor je o velikosti: ${(rejectedFile.size / 1_000_000).toFixed(2)}MB`}
            />
          </>
        ) : null}
      </UploaderWrap>
      <div className={classes.root}></div>
    </Wrap>
  );
};

const styles = (theme) => ({
  paper: {
    alignSelf: "flex-start",
    width: "200px"
  },
  dropzone: {
    height: "50px",
    fontFamily: theme.typography.fontFamily
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    width: "100%",
    height: "100%"
  }
} as const);

export default withStyles(styles)(PhotoUpload);

const Checkbox = styled.input`
  height: 20px;
  width: 20px;
  margin-right: 10px;
  cursor: pointer;
  z-index: 2000;
`;
const Consent = styled.label`
  font-size: 1em;
  color: grey;
  float: right;
  position: relative;
  top: 3px;
  left: -5%;
  width: 90%;
  margin-bottom: 20px;
  @media (max-width: 1113px) {
    left: 0;
  }
  @media (max-width: 424px) {
    top: -25px;
  }
`;

const Wrap = styled.div`
  width: 100%;
  height: auto;
  margin-top: 30px;
`;
const UploaderWrap = styled.div`
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 50px;
`;
const Title = styled.h2`
  font-size: 1.4em;
  color: #484848;
  font-family: OpenSansBold, sans-serif;
`;
const ErrorMessage = styled.div`
  color: rgb(228, 31, 37);
  float: left;
  position: relative;
  top: -20px;
  font-size: 0.7rem;
  margin: 10px;
`;
