import React from "react";
import styled from "styled-components";

import FairFormBody from "../containers/FairFormBody.container";

const WelcomeText = styled.p`
  width: 50%;
  margin: 40px auto;
  font-family: OpenSansRegular, sans-serif;
  color: #484848;
  text-align: center;
`;
const FairForm = ({ match }) => {
  return (
    <>
      <WelcomeText>
        Vítáme Vás na stránce, kde vytvoříte svůj e-jarmark. <br /> Tak hurá do
        toho &#128522; Držíme palce!
      </WelcomeText>
      <FairFormBody eFair={{ id: match.params.fairId }} />
    </>
  );
};

export default FairForm;
