import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { withApollo } from "react-apollo";
import { Route, withRouter } from "react-router-dom";

import FairForm from "../screens/FairForm";
import ReservationsList from "../screens/ReservationsList";

const Authenticated = ({ match, client, history: { push } }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let id;
    client
      .query({
        query: meEFairQuery,
        fetchPolicy: "network-only"
      })
      .then((res) => {
        setLoading(false);
        id = res.data.meEFair.eFair.id;
        if (res.data.meEFair.email || id !== null) {
          push(`/sprava/${id}/form`);
        } else {
          push("/login");
        }
      })
      .catch((e) => {
        setLoading(false);
        // push("/login");
      });
  }, [client, push]);
  return (
    <>
      <Route path="/sprava/:fairId/form" exact component={FairForm} />
      <Route
        path="/sprava/:fairId/rezervace"
        exact
        component={ReservationsList}
      />
    </>
  );
};

export default withRouter(withApollo(Authenticated));

const meEFairQuery = gql`
  {
    meEFair {
      id
      email
      eFair {
        id
      }
    }
  }
`;
