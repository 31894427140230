import { ApolloClient, InMemoryCache } from "apollo-client-preset";
import { ApolloLink } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";

export default () => {
  const middlewareLink = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem("token");
    operation.setContext({
      headers: {
        Authorization: token
      }
    });
    return forward(operation);
  });

  const httpLink = createUploadLink({
    uri: process.env.NODE_ENV === "development" ? "https://ejarmarky.abecedapenez.cz/api" : "/api"
    // uri: process.env.NODE_ENV === "development" ? "http://localhost:4000" : "/api"
  });
  const httpLinkAuth = middlewareLink.concat(httpLink);

  const cache = new InMemoryCache();

  return new ApolloClient({
    link: ApolloLink.from([httpLinkAuth]),
    cache,
    connectToDevTools: true
  });
};
