import React, { FC, useContext } from "react";
import styled from "styled-components";

import ProductCardFormComponent from "../components/ProductCardForm.component";
import { FormContext } from "../context/FormContext";

interface Props {
  submitting: boolean;
  newProductsValid: boolean;
}

const ProducWrapper: FC<Props> = ({ submitting, newProductsValid }) => {
  const context = useContext(FormContext);
  return (
    <>
      <Wrap>
        <Title>Nové výrobky</Title>
        <AddButton
          disabled={context.formDisabled}
          onClick={(e) => {
            e.preventDefault();
            const newRecognitionId = Math.max(...[
              ...(context.products ?? []),
              ...(context.newProducts ?? []),
            ].map((p) => p.recognitionId)) + 1;
            context.setNewProducts([
              ...context.newProducts,
              {
                name: "",
                recognitionId: newRecognitionId,
                description: "",
                price: "",
                amount: "",
                photoLink: ""
              }
            ]);
          }}
        >
          Přidat výrobek
        </AddButton>
        <CurrentProducts>
          {(context.newProducts || [])
            .map((product, index) => (
              <ProductCardFormComponent
                submitting={submitting}
                key={index}
                product={product}
                index={index}
              />
            ))
            .reverse()}
        </CurrentProducts>
        {context.products.length > 0 ? <Title>Vystavené výrobky</Title> : null}
        <CurrentProducts>
          {(context.products || [])
            .map((product, index) => {
              return (
                <ProductCardFormComponent
                  submitting={submitting}
                  key={index}
                  product={product}
                  index={index}
                />
              );
            })
            .reverse()}
        </CurrentProducts>
      </Wrap>
    </>
  );
};

export default ProducWrapper;

const Wrap = styled.div`
  clear: both;
  margin-top: 30px;
  position: relative;
  top: 50px;
`;
const CurrentProducts = styled.div`
  display: flex;
  justify-content: left;
  margin: 50px 0;
  flex-wrap: wrap;
  height: auto;
`;
const Title = styled.div`
  font-size: 1.4em;
  color: #484848;
  font-family: OpenSansBold, sans-serif;
  margin-bottom: 30px;
`;
const AddButton = styled.button`
  color: #fff;
  background-color: #ff6129;
  &:hover {
    background-color: #fa4a11;
  }
  width: 170px;
  height: 50px;
  border: none;
  margin: 25px 15% 40px 0;
  border-radius: 4px;
  outline: none;
  &:disabled {
    background-color: lightgrey;
    color: white;
    border-bottom-color: #a6a6a6;
    box-shadow: inset 0 -2px 0 0 #a6a6a6;
  }
`;
