import gql from "graphql-tag";
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useQuery } from 'react-apollo';
import styled from "styled-components";

import { IssuingPlace, IssuingPlaceProps } from "./IssuingPlace";
import { IssuingTimes } from "./IssuingTimes";

interface Props {
  id?: string;
  street?: string;
  setStreet: Dispatch<SetStateAction<string>>;
  town?: string;
  setTown: Dispatch<SetStateAction<string>>
  zip?: string;
  setZip: Dispatch<SetStateAction<string>>;
  remark?: string;
  setRemark: Dispatch<SetStateAction<string>>;
  eFair: any;
  streetValid?: boolean;
  townValid?: boolean;
  zipValid?: boolean;
  submitting?: boolean;
}

const reservationQuery = gql`
  query eFairReservations($id: ID!) {
    eFairReservations(id: $id) {
      id
    }
  }
`;

const Issuing: FC<Props & IssuingPlaceProps> = (props) => {
  const reservationsQuery = useQuery(reservationQuery, {
    skip: !props.id,
    fetchPolicy: "network-only",
    variables: {
      id: props.id
    }
  });
  return (
    <>
      <IssuingPlace {...props} />
      <IssuingTimes reservationsQuery={reservationsQuery} {...props} />
    </>
  );
};
export default Issuing;

export const ActionHint = styled.div`
  color: #6c6c6c;
  font-size: 0.8em;
  position: relative;
  top: -15px;
`;

export const ErrorMessage = styled.div`
  color: rgb(228, 31, 37);
  width: auto;
  clear: both;
  margin: 6px;
  font-size: 0.7rem;
`;
