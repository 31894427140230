import React, { FC, useContext } from "react";
import styled from "styled-components";

import { ActionHint, ErrorMessage } from ".";
import { FormContext } from "../../context/FormContext";

export interface IssuingPlaceProps {
  street?: string;
  setStreet: (s?: string) => unknown;
  town?: string;
  setTown: (s?: string) => unknown;
  zip?: string;
  setZip: (s?: string) => unknown;
  remark?: string;
  setRemark: (s?: string) => unknown;

  streetValid?: boolean;
  townValid?: boolean;
  zipValid?: boolean;

  submitting: boolean;
}

export const IssuingPlace: FC<IssuingPlaceProps> = ({
  street,
  setStreet,
  town,
  setTown,
  zip,
  setZip,
  remark,
  setRemark,
  streetValid,
  townValid,
  zipValid,
  submitting
}) => {
  const context = useContext(FormContext);

  return (
    <>
      <Title>Výdejní místo</Title>
      <ActionHint>
        Napište, kde si mohou zákazníci vyzvednout rezervované výrobky
      </ActionHint>
      <PlaceWrap>
        <LeftColumn>
          <StreetWrap>
            <Label {...{ htmlFor: "street" }}>Ulice a č.p.*</Label>
            <Street
              className="mandatory"
              invalid={!street && submitting}
              disabled={context.formDisabled}
              type="text"
              value={street}
              name="street"
              onChange={(e) => setStreet(e.target.value)}
            />
            {!streetValid && submitting ? (
              // {true ? (
              <ErrorMessage>"Ulice a č.p." je povinné pole</ErrorMessage>
            ) : null}
          </StreetWrap>
          <ZipCityWrap>
            <CityWrap>
              <Label {...{ htmlFor: "city" }}>Město*</Label>
              <City
                className="mandatory"
                invalid={!townValid && submitting}
                disabled={context.formDisabled}
                name="city"
                {...{ cols: 20, rows: 5 }}
                value={town}
                onChange={(e) => setTown(e.target.value)}
              />
              {!townValid && submitting ? (
                <ErrorMessage>"Město" je povinné pole</ErrorMessage>
              ) : null}
            </CityWrap>
            <ZipWrap>
              <ZipLabel {...{ htmlFor: "zipcode" }}>PSČ*</ZipLabel>
              <ZipCode
                className="mandatory"
                invalid={!zipValid && submitting}
                type="text"
                pattern="[0-9]*"
                maxLength={5}
                disabled={context.formDisabled}
                name="zipcode"
                value={zip}
                onChange={(e) => {
                  const zipCode = e.target.validity.valid
                    ? e.target.value
                    : zip;
                  setZip(zipCode);
                }}
              />
              {!zipValid && submitting ? (
                // {true ? (
                <ZipErrorMessage>"PSČ" je povinné pole</ZipErrorMessage>
              ) : null}
            </ZipWrap>
          </ZipCityWrap>
        </LeftColumn>
        <RemarkWrap>
          <RemarkLabel {...{ htmlFor: "remark" }}>Poznámka</RemarkLabel>
          <Remark
            disabled={context.formDisabled}
            name="remark"
            cols={20}
            rows={5}
            placeholder="Aby zákazníci nebloudili, napište pár detailů o výdejním místě. Např.: Výrobky si můžete vyzvednout ve školní družině, která se nachází v prvním patře."
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        </RemarkWrap>
      </PlaceWrap>

    </>
  );
};

const Title = styled.h2`
  font-size: 1.4em;
  color: #484848;
  font-family: OpenSansBold, sans-serif;
  margin-top: 40px;
`;

const Label = styled.div`
  color: #484848;
  margin-top: 20px;
`;

const PlaceWrap = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1113px) {
    width: 100%;
    display: table;
  }
`;

const StreetWrap = styled.div`
  width: 100%;
`;

const Street = styled.input<{ invalid?: boolean }>`
  padding: 0.5rem 12px;
  box-sizing: border-box;
  outline: 0;
  pointer-events: all;
  border-radius: 3px;
  font-weight: 200;
  display: inline-block;
  color: #313a45;
  background-color: #fff;
  background-image: none;
  border: 1px solid #929fa6;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 1px 3px 0 #b4bfc5 inset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 98%;
  font-size: 1em;
  height: 30px;
  border: ${(props) =>
    props.invalid ? "1px solid rgb(228, 31, 37)" : "1px solid grey"};
  @media (max-width: 1113px) {
    width: 99.5%;
  }
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  @media (max-width: 1113px) {
    width: 100%;
  }
`;

const ZipCityWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1113px) {
    width: 100%;
  }
`;

const CityWrap = styled.div`
  width: 70%;
  @media (max-width: 1113px) {
    width: 100%;
  }
`;

const City = styled.input<{ invalid?: boolean }>`
  padding: 0.5rem 12px;
  box-sizing: border-box;
  outline: 0;
  pointer-events: all;
  border-radius: 3px;
  font-weight: 200;
  display: inline-block;
  color: #313a45;
  background-color: #fff;
  background-image: none;
  border: 1px solid #929fa6;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 1px 3px 0 #b4bfc5 inset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 95%;
  font-size: 1em;
  height: 30px;
  border: ${(props) =>
    props.invalid ? "1px solid rgb(228, 31, 37)" : "1px solid grey"};
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
`;

const ZipWrap = styled.span`
  width: 25%;
  height: auto;
  margin-top: 20px;
`;

const ZipCode = styled.input<{ invalid?: boolean }>`
  padding: 0.5rem 12px;
  box-sizing: border-box;
  outline: 0;
  pointer-events: all;
  border-radius: 3px;
  font-weight: 200;
  display: inline-block;
  color: #313a45;
  background-color: #fff;
  background-image: none;
  border: 1px solid #929fa6;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 1px 3px 0 #b4bfc5 inset;
  -webkit-appearance: none;
  appearance: none;
  width: 92.5%;
  text-align: right;
  font-size: 1rem;
  height: 30px;
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  border: ${(props) =>
    props.invalid ? "1px solid rgb(228, 31, 37)" : "1px solid grey"};
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
  @media (max-width: 1113px) {
    font-size: 0.8rem;
    width: 98%;
  }
`;

const ZipLabel = styled.div`
  color: #484848;
`;

const ZipErrorMessage = styled.div`
  color: rgb(228, 31, 37);
  font-size: 0.7rem;
  text-align: right;
  display: inline-block;
  white-space: nowrap;
  margin: 5px 0 6px 6px;
  @media (max-width: 815px) {
    float: right;
  }
`;

const RemarkWrap = styled.div`
  float: right;
  width: 45%;
  @media (max-width: 1113px) {
    margin: 40px auto 0 auto;
    float: none;
    width: 100%;
    position: static;
  }
`;

const Remark = styled.textarea`
  padding: 0.5rem 12px;
  box-sizing: border-box;
  outline: 0;
  pointer-events: all;
  border-radius: 3px;
  font-weight: 200;
  display: inline-block;
  color: #313a45;
  background-color: #fff;
  background-image: none;
  border: 1px solid #929fa6;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 1px 3px 0 #b4bfc5 inset;
  -webkit-appearance: none;
  appearance: none;
  width: 99.5%;
  font-size: 0.8em;
  height: 65.7%;
  resize: none;
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
`;

const RemarkLabel = styled.div`
  color: #484848;
  margin-top: 1.2rem;
  text-align: left;
`;
