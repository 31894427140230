import gql from "graphql-tag";
import { graphql } from "react-apollo";

const submitEFairUpdateProductMutation = graphql(
  gql`
    mutation SubmitEFairUpdateProductMutation(
      $id: ID!
      $name: String!
      $description: String!
      $price: Int!
      $amount: Int!
      $image: Upload
      $fileExt: String
      $type: String
      $photoLink: String
      $recognitionId: Int
    ) {
      submitEFairUpdateProduct(
        data: {
          id: $id
          name: $name
          description: $description
          price: $price
          amount: $amount
          image: $image
          fileExt: $fileExt
          type: $type
          photoLink: $photoLink
          recognitionId: $recognitionId
        }
      ) {
        id
      }
    }
  `,
  {
    name: "submitEFairUpdateProductMutation"
  }
);

export default submitEFairUpdateProductMutation;
