import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import introImage from "../assets/images/jak_na_e-jarmark_mobil_pro_modre_pozadi_02 (1).svg";

const ResponsiveWrap = styled.div`
  width: 100%;
  margin: 0 auto;
`;
const Button = styled.button`
    color: #fff !important;
    background-color: #ff6129;
    outline: 0;
    //background-color: #0078b4;
    //color: white;
    margin: 20px auto;
    border-radius: 4px;
    width: 30%;
    border: none;
    padding: 10px 12px;
    outline: none;

    &:hover {
        background-color: #fa4a11;
    }

    @media (max-width: 1113px) {
        width: 100%;
        margin: 20px auto 50px auto;
    }
`;
const Wrapper = styled.div`
  max-width: 1200px;
  width: 90%;
  margin: 20px auto;
  display: flex;
  @media (max-width: 1113px) {
    display: table;
  }
`;
const Column = styled.div`
  flex: 45%;
  //background-color: aqua;
  margin-bottom: 30px;
  height: 100%;
  @media (max-width: 1113px) {
    font-size: 18px;
  }
`;
const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  //background-color: #f50057;
`;
const Image = styled.img`
  width: 70%;
  //background-color: #3f51b5;
  height: auto;
  float: right;
  margin: 30px auto;
  @media (max-width: 1113px) {
    float: none;
    margin: 0 auto;
    width: 80%;
  }
`;
const HeadTitle = styled.div`
  color: #484848;
  font-size: 2em;
  width: 100%;
  font-weight: bold;
  @media (max-width: 1113px) {
    font-size: 24px;
    text-align: center;
  }
`;
const Description = styled.div`
  color: #484848;
  width: 100%;
  margin: 30px auto;
  @media (max-width: 1113px) {
    font-size: 18px;
    text-align: center;
  }
`;
const SuperBold = styled.span`
  color: #484848;
  font-weight: bolder;
  font-family: OpenSansBold, sans-serif;
`;

const HeaderButton = ({ value, linkPath }) => {
  return (
    <>
      <Wrapper>
        <Column>
          <ImageWrapper>
            <Image src={introImage} />
          </ImageWrapper>
        </Column>
        <Column>
          <ResponsiveWrap>
            <HeadTitle>
              <SuperBold>
                Pomozte čtvrťákům při poznávání světa peněz, podpořte jejich
                kreativitu a vynalézavost. Nakupujte na e-jarmarku
              </SuperBold>
            </HeadTitle>
            <Description>
              <SuperBold>E-jarmark</SuperBold> je součástí projektu Podnikavá
              třída – Abecedy peněz České spořitelny, v rámci kterého čtvrťáci
              pomocí interaktivního programu přichází penězům na kloub. Formou
              hry si žáci čtvrtých tříd vyzkouší, že peníze sice nekoušou, ale
              člověk to s nimi musí umět. Založí si vlastní firmičku s omezeným
              rozpočtem, vymyslí vlastní výrobek a pak ho nabídnou na závěrečném
              jarmarku. A co s prvním výdělkem v životě? Za ten mohou udělat
              radost buď sobě, nebo potřebným.
              <br></br>
              <br></br>
              <SuperBold>
                Právě k výdělku můžete žákům z místní 4. třídy pomoct i vy.
                Stačí si objednat některé z jejich výrobků.
              </SuperBold>
              <br></br>
              <br></br>
              Je to jednoduché. Začněte výběrem konkrétního e-jarmarku...
            </Description>
          </ResponsiveWrap>
          <Link to="/hledat">
            <ResponsiveWrap>
              <Button>Hledat e-jarmark</Button>
            </ResponsiveWrap>
          </Link>
        </Column>
      </Wrapper>
    </>
  );
};

export default HeaderButton;
