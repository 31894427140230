import React, { FC, ReactNode } from "react";
import styled from "styled-components";

interface Props {
  text?: string | number;
  title?: ReactNode;
  bgColor?: string;
  color?: string;
}

const CommentBox: FC<Props> = ({ text, title, bgColor, color }) => {
  return (
    <>
      <Wrap bgColor={bgColor} color={color}>
        <Title>{title}</Title>
        {`"${text}"`}
      </Wrap>
    </>
  );
};

export default CommentBox;

const Wrap = styled.div<{ color?: string; bgColor?: string }>`
  background-color: ${props => props.bgColor ? props.bgColor : "#be2321"};
  padding: 20px 40px;
  height: auto;
  color: ${props => props.color ? props.color : "white"};
  border-radius: 4px;
  margin-top: 40px;
`;
const Title = styled.p`
  font-size: 0.8em;
`;
