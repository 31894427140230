import gql from "graphql-tag";
import React, { FC, Fragment } from "react";
import { DataValue, graphql } from "react-apollo";
import NumberFormat from "react-number-format";
import styled from "styled-components";

interface Props {
  eFair: { id: string };
}

interface InnerProps {
  eFairQuery: DataValue<{ eFair: { reservations: Reservation[] } }>;
}

interface Reservation {
  fullName: string;
  email: string;
  product: {
    recognitionId: string;
    namePublic: string;
  };
  amount: number;
  totalPrice: number;
}

const ReservationsBody: FC<Props & InnerProps> = ({ eFairQuery: { eFair = {}, ...eFairQuery } }) => {
  const reservations =
    !!eFair && !!eFair.reservations ? [...eFair.reservations] : [];
    /// /////////// UI Testing of reservations
  // for (let i = 0; i < 200; i++) {
  //   reservations.push({
  //     fullName: "FakeLongNameFakeLongNameFakeLongNameFakeLongNameFakeLongNameFakeLongName FakeLongNameFakeLongNameFakeLongNameFakeLongName FakeLongNameFakeLongNameFakeLongName",
  //     email: "fakelongemailfakelongemailfakelongemailfakelongemailfakelongemailfakelongemailfakelongemailfakelongemailfakelongemailfakelongemailfakelongemail@mail.com",
  //     product: {
  //       recognitionId: "",
  //       namePublic: "FakeVyrobekFakeVyrobekFake VyrobekFakeVyrobek 85"
  //     },
  //     amount: 345684564654,
  //     totalPrice: 9999
  //   });
  // }
  const grouped = [...reservations].sort((a, b) => a.email.localeCompare(b.email));

  return (
    <>
      {!!eFair && !!reservations && reservations.length !== 0 ? (
        <>
          <ReservationsWrap>
            <ExportButton onClick={() => window.print()}>
              Vytisknout
            </ExportButton>
            <Wrap>
              <Table id="pdf">
                <Thead>
                  <Th>Celé jméno</Th>
                  <Th>Číslo a název výrobku</Th>
                  <Th>Kusy</Th>
                  <Th>Celková cena</Th>
                </Thead>
                <Tbody>
                  {grouped.map((el, i) => {
                    return (
                      <Fragment key={i}>
                        <Tr>
                          <Td>{el.fullName}</Td>
                          <Td>
                            {el.product.recognitionId ? el.product.recognitionId + ". " + el.product.namePublic : el.product.namePublic}
                          </Td>
                          <Td right>{el.amount}</Td>
                          <Td right>
                            <NumberFormat
                              displayType="text"
                              value={el.totalPrice}
                              thousandSeparator={" "}
                            />{" "}
                            Kč
                          </Td>
                        </Tr>
                      </Fragment>
                    );
                  })}
                </Tbody>
                <tfoot></tfoot>
              </Table>
            </Wrap>
          </ReservationsWrap>
        </>
      ) : (
        <NoReservations>Zatím nemáte žádné rezervace</NoReservations>
      )}
    </>
  );
};

const eFairQuery = graphql<Props>(
  gql`
    query EFair($id: ID!) {
      eFair(id: $id) {
        id
        classroom {
          id
          classroomName
          school {
            id
            name
          }
        }
        reservations {
          id
          amount
          totalPrice
          fullName
          email
          product {
            namePublic
            recognitionId
          }
        }
      }
    }
  `,
  {
    name: "eFairQuery",
    options: (props) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: props.eFair.id
      }
    })
  }
);

export default eFairQuery(ReservationsBody as any) as any as FC<Props>;

const Wrap = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
`;

const ExportButton = styled.button`
  color: #fff;
  background-color: #ff6129;
  &:hover {
    background-color: #fa4a11;
  }
  width: 140px;
  height: 40px;
  border: none;
  float: right;
  margin-top: 25px;
  border-radius: 4px;
  outline: none;
  @media (max-width: 495px) {
    display: none;
  }

  @media print {
    display: none;
    margin-top: 0;
  }
`;
const Table = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  text-align: center;
  width: 100%;
  margin: 20px auto 0 auto;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px #c7c7c7;

  @media print {
    background-color: white;
    box-shadow: none;
  }
`;
const Th = styled.th`
  text-align: left;
  padding: 23px;
  font-size: 1.2em;
  @media (max-width: 495px) {
    padding: 0;
    font-size: 0.9em;
  }

  @media print {
    text-align: center;
    padding: 6px 0;
  }
`;
const Thead = styled.tr`
  border: none;
  text-align: left;
  padding: 8px 0;

  @media print {
    padding: 0;
  }
`;
const Td = styled.td<{ right?: boolean }>`
  border: 5px solid transparent;
  padding: 20px;
  text-align: ${p => !p.right ? "left" : "right"};
  @media (max-width: 495px) {
    padding: 0;
    height: 40px;
    width: 15%;
    font-size: 0.9em;
  }

  @media print {
    padding: 6px 0;
  }
`;
const Tr = styled.tr`
  background-color: white;
  margin-top: 20px;
  &:nth-child(even) {
    background: #f4f6fa;
  }

  @media print {
    box-shadow: none;
    margin-top: 0;

    &:nth-child(even) {
      background: #f4f6fa;
    }
  }  
`;
const Tbody = styled.tbody`
  width: 100%;
`;
const NoReservations = styled.p`
  color: #484848;
  margin: 10% auto;
  height: auto;
  font-size: 1.5em;
  text-align: center;
  @media (max-width: 1113px) {
    font-size: 1em;
  }
`;
const ReservationsWrap = styled.div`
  max-width: 1200px;
  width: 90%;
  margin: 0 auto 50px auto;
  height: auto;

  & > ${ExportButton} {
    margin-left: 12px;
  }

  @media print {
    width: 100%;
    max-width: auto;
  }
`;
