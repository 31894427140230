import React, { FC, useContext } from "react";
import styled from "styled-components";

import { ActionHint, ErrorMessage } from ".";
import { FormContext } from "../../context/FormContext";
import { IssuingDay } from "./IssuingDay";

export interface IssuingTimesProps {
  submitting: boolean;
  reservationsQuery: any;
}

export const IssuingTimes: FC<IssuingTimesProps> = ({ reservationsQuery, submitting }) => {
  const context = useContext(FormContext);

  return (
    <>
      <Days>Výdejní termíny*</Days>
      <ActionHint>
        Přidejte termíny, kdy budete s dětmi čekat na zákazníky, kteří si
        přijdou vyzvednout výrobky
      </ActionHint>
      {(context.issuingDays.length === 0 || (context.issuingDays.length === 1 && context.issuingDays[0].delete === true)) &&
        submitting ? (
          <ErrorMessage className="mandatory">
            "Výdejní termín" je povinné pole
          </ErrorMessage>
        ) : null}
      <br></br>
      {context.issuingDays.length > 0
        ? context.issuingDays.map((time, i) => {
          if (time.delete) return null;
          return (
            <IssuingDay
              key={i}
              time={time}
              submitting={submitting}
              reservationsQuery={reservationsQuery}
              i={i}
            />
          );
        })
        : null}
      <AddButton
        disabled={context.formDisabled}
        onClick={(e) => {
          e.preventDefault();
          if (reservationsQuery.length > 0) {
            context.setChangeIssuingDayModalOpened(true);
            context.setChangeIssuingDayOnSubmitHandler(() => () => {
              context.addNewIssuingDayHandler();
            });
          } else {
            context.addNewIssuingDayHandler();
          }
        }}
      >
        Přidat další termín
      </AddButton>
    </>
  );
};

export const Wrap = styled.div<{ invalid?: boolean }>`
  float: left;
  border: ${(props) => (props.invalid ? "1px solid rgb(228, 31, 37)" : "none")};
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0 6px 0;
  @media (max-width: 1113px) {
    float: none;
    border: ${(props) =>
      props.invalid ? "1px solid rgb(228, 31, 37)" : "1px solid grey"};
    width: 93.5%;
  }
`;

const Days = styled.div`
  color: #6c6c6c;
  margin-top: 50px;
  margin-bottom: 17px;
  font-size: 1.4em;
`;
const AddButton = styled.button`
  color: #fff;
  background-color: #ff6129;
  &:hover {
    background-color: #fa4a11;
  }
  width: 170px;
  height: 50px;
  border: none;
  margin: 25px 15% 40px 0;
  border-radius: 4px;
  outline: none;
  &:disabled {
    background-color: lightgrey;
    color: white;
    border-bottom-color: #a6a6a6;
    box-shadow: inset 0 -2px 0 0 #a6a6a6;
  }
`;
