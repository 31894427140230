import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import headerLogo from "../assets/images/cs-logo.svg";
import logo from "../assets/images/logo_cs_2023.svg";
import banner from "../assets/images/CS_Abeceda_penez_web_banner_ejarmark_2000x400_04.jpg";
import footerLogo from "../assets/images/footer-logo1.svg";
import openInNew from "../assets/images/open_in_new-24px.svg";
import AdminTitleSection from "./AdminTitleSection.component";
import GeneralInfoPublic from "./GeneralInfoPublic.component";
import HeaderButton from "./HeaderButton.component";

const FairsFilter = ({ children }) => {
  const [buttonValue, setButtonValue] = useState("Přehled e-jarmarků");
  const [linkPath, setLinkPath] = useState("/");

  const location = useLocation();
  const path = location.pathname;
  const detailPathStart = path.slice(0, 7);
  const adminPathStart = path.slice(0, 7);

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/hledat") {
      setLinkPath("/login");
      setButtonValue("Správa e-jarmarku");
    } else if (detailPathStart === "/detail") {
      setLinkPath("/login");
      setButtonValue("Správa e-jarmarku");
    } else if (path === "/login") {
      setButtonValue("Přehled e-jarmarků");
      setLinkPath("/");
    } else if (adminPathStart === "/sprava") {
      setButtonValue("Odhlásit se");
      setLinkPath("/login");
    }
  }, [adminPathStart, detailPathStart, location.pathname, path]);

  return (
    <>
      <Wrap>
        <Header>
          <HeaderContent>
            <Version>Build 1.0.6</Version>
            <Redirect
              href="https://www.abecedapenez.cz/cs/uvod"
              target="_blank"
            >
              &rarr; Přejít na web Abecedy peněz
            </Redirect>
          </HeaderContent>
        </Header>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <LogoSection>
            <Link to="/" style={{ height: '51px' }}>
              <Logo src={logo} />
            </Link>
            <HeaderButton value={buttonValue} linkPath={linkPath} />
          </LogoSection>
        </div>
        {path === "/" || path === "/hledat" ? (
          <>
            <TitleSection searchPage={path === "/hledat"}>
              <HeadTitle searchPage={path === "/hledat"}>
                E-jarmarky
              </HeadTitle>
              <Description>
                {path === "/"
                  ? "Čtvrťáci se učí správně hospodařit s penězi a plní si při tom\n" +
                    "                své sny"
                  : "Výrobky školáků, nyní i online"}
              </Description>
              {path === "/" ? (
                <>
                  <Buttons>
                    <Link to="/hledat">
                      <SearchButton>Hledat e-jarmark</SearchButton>
                    </Link>
                    <a
                      href="https://www.csas.cz/cs/page/abecedapenez/ctvrta-trida"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <InfoButton>Více o programu</InfoButton>
                    </a>
                  </Buttons>
                </>
              ) : null}
            </TitleSection>
          </>
        ) : null}
        {path === "/login" || path === "/zapomenute-heslo" ? (
          <>
            <LoginTitleSection>
              <LoginHeadTitle>Správa e-jarmarku</LoginHeadTitle>
              <LoginDescription>
                Přihlaste se a dotáhněte svůj e-jarmark k dokonalosti
              </LoginDescription>
            </LoginTitleSection>
          </>
        ) : null}
        {detailPathStart === "/detail" ? (
          <>
            <BackWrapper>
              <div
                style={{
                  maxWidth: "1200px",
                  width: "90%",
                  margin: "auto"
                }}
              >
                <BackButton to="/hledat">{"< Zpět na vyhledávání"}</BackButton>
              </div>
            </BackWrapper>
            <GeneralInfoPublic eFairId={path.split("/")[2]} />
          </>
        ) : null}
        {path.split("/")[1] === "sprava" ? (
          <AdminTitleSection
            eFairId={path.split("/")[2]}
            adminPage={path.split("/")[3]}
          />
        ) : null}
        <Content>{children}</Content>
        <FooterWrap>
          <Disclaimer>
            Podívejte se na pravidla e-jarmarku v plném znění{" "}
            <a
              href="https://www.csas.cz/content/dam/cz/csas/www_csas_cz/dokumenty/abecedapenez/pravidla_ejarmark.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              zde{" "}
              <img
                style={{
                  marginLeft: "5px",
                  width: "14px"
                }}
                src={openInNew}
              />
            </a>
          </Disclaimer>
          <Hr />
          <div
            style={{
              width: "25px",
              margin: "25px auto 0 auto",
              position: "relative",
              left: "-60px"
            }}
          >
            {/*<FooterLogo src={footerLogo} />*/}
          </div>
          <Brand>© Česká spořitelna, a. s. Všechna práva vyhrazena. </Brand>
          <div
            style={{
              width: "auto",
              margin: "auto",
              textAlign: "center"
            }}
          >
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.csas.cz/cs/podminky-pouzivani-webu"
            >
              Podmínky používání{" "}
            </FooterLink>
            <span
              style={{
                fontSize: "4px",
                color: "#004a7b",
                position: "relative",
                top: "-4px"
              }}
            >
              {"\u2B24"}
            </span>
            <FooterLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.csas.cz/cs/zasady-zpracovani-osobnich-udaju"
            >
              {" "}
              Ochrana osobních údajů
            </FooterLink>
          </div>
        </FooterWrap>
      </Wrap>
    </>
  );
};

export default FairsFilter;

const Wrap = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  background-color: #0b1f42;
  z-index: 1;

  @media print {
    background: transparent;
    font-size: 80%;
  }
`;
const Content = styled.div`
  margin: auto;
  position: relative;
  width: 100%;
  background-color: #f4f6fa;
`;
const Header = styled.div`
  background-color: #004a7b;
  font-size: 0.75em;
  width: 100%;
  text-align: right;
  height: 24px;
  border-bottom: 1px solid #5b83ae;

  @media print {
    display: none;
  }
`;
const HeaderContent = styled.div`
  max-width: 1200px;
  width: 90%;
  margin: auto;
`;
const Redirect = styled.a`
  position: relative;
  top: 5px;
  text-decoration: none;
  color: #ffffff;
`;
const LogoSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background-color: rgb( 40,112,237);
  margin: 32px 32px 0 32px;
  max-width: 1200px;
  width: 100%;
  //height: 88px;
  padding: 28px;
  @media (max-width: 530px) {
    height: 140px;
  }
  @media print {
    max-width: auto;
    width: 100%;
    height: auto;
  }
`;
const Logo = styled.img`
  height: 51px;

  @media print {
    height: 32px;
    padding-top: 8px;
    top: 0;
  }
`;
const Version = styled.span`
  float: left;
  color: white;
  margin-top: 5px;
`;
const TitleSection = styled.div`
  width: 100%;
  min-height: ${(props) => (props.searchPage ? "200px" : "340px")};
  color: #484848;
  //background-image: url(${banner});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  // @media (max-width: 1113px) {
  //   background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
  //     url(${banner}) no-repeat center top;
  // }
`;
const HeadTitle = styled.div`
  font-size: 2.3em;
  margin: ${(props) =>
    props.searchPage === true
      ? "3rem auto 20px auto"
      : "4.5rem auto 20px auto"};
  width: 90%;
  max-width: 1200px;
  font-weight: bold;
  color: white;
  font-family: OpenSansBold, sans-serif;
  @media (max-width: 1113px) {
    margin: 5rem auto 10px auto;
  }
`;
const Description = styled.div`
  color: white;
  font-size: 1.4em;
  margin: 20px auto;
  width: 90%;
  max-width: 1200px;
  @media (max-width: 1113px) {
    font-size: 0.9em;
  }
`;
const SearchButton = styled.button`
    color: #fff;
    background-color: #ff6129;
    &:hover {
      background-color: #fa4a11;
    }
    margin-top: 10px;
    border-radius: 4px;
    padding: 10px 12px;
    border: none;
    outline: none;
    @media (max-width: 1113px) {
        width: 47%;
        margin: 15px auto;
    }
`;
const InfoButton = styled.button`
  background-color: transparent;
  border: 1px solid white;
  color: white;
  margin-left: 20px;
  margin-top: 10px;
  border-radius: 4px;
  padding: 10px 12px;
  outline: none;
  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
  @media (max-width: 1113px) {
    width: 47%;
    margin: 15px auto 0 6%;
  }
`;
const Buttons = styled.div`
  margin: 2rem auto 0 auto;
  width: 90%;
  max-width: 1200px;
`;
const LoginTitleSection = styled.div`
  width: 100%;
  height: 250px;
  color: white;
  // background-image: url(${banner});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  //background-color: white;
  @media (max-width: 530px) {
    height: 120px;
  }
`;
const LoginHeadTitle = styled.div`
  font-size: 2.3em;
  font-weight: bold;
  width: 90%;
  max-width: 1200px;
  margin: 5rem auto 20px auto;
  @media (max-width: 530px) {
    font-size: 20px;
    margin: 2rem auto 5px auto;
  }
`;
const LoginDescription = styled.div`
  font-size: 0.9em;
  width: 90%;
  max-width: 1200px;
  margin: auto;
`;
const FooterWrap = styled.div`
  grid-row-start: 2;
  grid-row-end: 3;
  //height: 266px;
  width: 100%;
  background-color: #65b8ff;
  padding: 18px 0;
  @media print {
    display: none;
  }
`;
const Brand = styled.div`
  color: #195988;
  width: auto;
  text-align: center;
  margin: 10px auto 5px auto;
  font-size: 0.85em;
  letter-spacing: 0.4px;
  font-family: OpenSansLight, sans-serif;
  //background-color: #f50057;
`;
const FooterLink = styled.a`
  text-decoration: none;
  color: #004a7b;
  font-size: 0.85em;
  margin: 0 7px;
  letter-spacing: 0.1px;
  font-family: OpenSansRegular, sans-serif;
  &:hover {
    border-bottom: 2px solid #004a7b;
  }
`;
const Hr = styled.div`
  width: 100%;
  position: relative;
  height: 1px;
  background-color: #83b9d0;
`;
const Disclaimer = styled.p`
  color: #004a7b;
  position: relative;
  width: auto;
  font-size: 1em;
  text-align: center;
`;
const BackWrapper = styled.div`
  width: 100%;
  height: auto;
  z-index: 1000;
  padding: 8px 0;
`;
const BackButton = styled(Link)`
  border: none;
  background-color: transparent;
  padding: 0;
  color: #0078b4;
  text-decoration: none;
  z-index: 2000;
  font-size: 0.8em;
  &:hover {
    border-bottom: 2px solid #0078b4;
  }
`;
