import gql from "graphql-tag";
import { graphql } from "react-apollo";

const revertChangesMutation = graphql(
  gql`
    mutation revertChangesMutation($id: ID!) {
      revertChanges(id: $id) {
        id
      }
    }
  `,
  {
    name: "revertChangesMutation"
  }
);

export default revertChangesMutation;
