import React from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";

const Button = styled.button`
  color: #fff;
  background-color: #ff6129;
  &:hover {
    background-color: #fa4a11;
  }
  outline: 0;
  width: auto;
  padding: 5px 20px;
  height: 40px;
  float: right;
  border: none;
  margin: 0;
  border-radius: 4px;
  outline: none;
  @media (max-width: 530px) {
    width: 100%;
    margin: 10px auto;
    position: relative;
    top: 20px;
    float: none;
  }
  border-bottom-color: #075d89;
  cursor: pointer;

  @media print {
    display: none;
  }
`;
const StyledLink = styled(Link)``;

const HeaderButton = ({ value, linkPath, history: { push } }) => {
  return (
    <>
      <StyledLink to={linkPath}>
        <Button
          className="btn btn-default eg_btn-prim-bright"
          onClick={() => {
            localStorage.removeItem("token");
            push("login");
          }}
        >
          {value}
        </Button>
      </StyledLink>
    </>
  );
};

export default withRouter(HeaderButton);
