import CircularProgress from "@material-ui/core/CircularProgress";
import gql from "graphql-tag";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { ComponentType, FC, useEffect, useMemo, useState } from "react";
import { DataValue, graphql, MutationFunction } from "react-apollo";
import { RouteComponentProps, withRouter } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import styled from "styled-components";

import ChangeIssuingDayModal from "../components/ChangeIssuingDay.component";
import CommentBox from "../components/CommentBox.component";
import FairDate from "../components/FairDate.component";
import FairState from "../components/FairState.component";
import GeneralInfo from "../components/GeneralInfo.component";
import Issuing from "../components/Issuing";
import NoteModal from "../components/NoteModal.component";
import PhotoUpload from "../components/PhotoUpload.component";
import { FormContext } from "../context/FormContext";
import _revertChangesMutation from "../queries/revertChangesMutation";
import _submitEFairMutation from "../queries/submitEFairMutation";
import submitEFairNewProductMutation from '../queries/submitEFairNewProductMutation';
import submitEFairUpdateProductMutation from '../queries/submitEFairUpdateProductMutation';
import ProducWrapper from "./ProductWrapper.container";

const youtubeEmbed = require("youtube-embed");

interface FairFormBodyProps {
  eFair: { id: number };
}

interface InnerFairFormBodyProps extends FairFormBodyProps, RouteComponentProps {
  submitEFairMutation: MutationFunction<any, any>;
  submitEFairNewProductMutation: MutationFunction<any, any>
  submitEFairUpdateProductMutation: MutationFunction<any, any>
  uploadEFairImageMutation: MutationFunction<any, any>;
  eFairQuery: DataValue<{ eFair: any }>;
}

const FairFormBody: FC<InnerFairFormBodyProps> = ({
  submitEFairMutation,
  submitEFairNewProductMutation,
  submitEFairUpdateProductMutation,
  uploadEFairImageMutation,
  eFairQuery: { eFair = {}, refetch },
  history: { push }
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [saveStatus, setSaveStatus] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [formDisabled, setFormDisabled] = useState(true);
  const [videoLink, setVideoLink] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactEmailValid, setContactEmailValid] = useState(false);
  const [introText, setIntroText] = useState("");
  const [introTextValid, setIntroTextValid] = useState(true);
  const [goal, setGoal] = useState("");
  const [goalValid, setGoalValid] = useState(true);
  const [products, setProducts] = useState([]);
  const [productsValid, setProductsValid] = useState(true);
  const [from, setFrom] = useState(null);
  const [fromValid, setFromValid] = useState(true);
  const [to, setTo] = useState(null);
  const [toValid, setToValid] = useState(true);
  const [street, setStreet] = useState("");
  const [streetValid, setStreetValid] = useState(true);
  const [town, setTown] = useState("");
  const [townValid, setTownValid] = useState(true);
  const [zip, setZip] = useState("");
  const [zipValid, setZipValid] = useState(true);
  const [remark, setRemark] = useState("");
  const [issuingDays, setIssuingDays] = useState([]);
  const [issuingDaysValid, setIssuingDaysValid] = useState(true);
  const [files, setFiles] = useState([]);
  const [checked, setChecked] = useState(false);
  const [newProducts, setNewProducts] = useState([]);
  const [newProductsValid, setNewProductsValid] = useState(true);
  const [newFiles, setNewFiles] = useState([]);
  const [isMandatory, setIsMandatory] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isChangeIssuingDayModalOpened, setChangeIssuingDayModalOpened] = useState(false);
  const [issuingTimeChangeReason, setIssuingTimeChangeReason] = useState("");
  const [isAddedNewIssuingDay, setIsAddedNewIssuingDay] = useState(false);
  const [changeIssuingDayOnSubmitHandler, setChangeIssuingDayOnSubmitHandler] = useState(() => () => {});
  const [hasChangeIssuingDayModalAlreadyDisplayed, setHasChangeIssuingDayModalAlreadyDisplayed] = useState(false);
  const [managerNote, setManagerNote] = useState("");

  useEffect(() => {
    if (eFair === undefined || eFair === null) {
      localStorage.removeItem("token");
      push("/login");
      return;
    }
    if (eFair.approval === "Čeká na schválení") {
      setFormDisabled(true);
    } else {
      setFormDisabled(false);
    }
    setManagerNote(eFair.managerNote ?? "");
    setVideoLink(eFair.videoLink ?? "");
    setContactEmail(eFair.contactEmail ?? "");
    setIntroText(eFair.introText ?? "");
    setGoal(eFair.goal ?? "");
    setFrom(eFair.from);
    setTo(eFair.to);
    setChecked(eFair.consent);
    setStreet(eFair.issuingPlace?.streetAndNumber ?? "");
    setTown(eFair.issuingPlace?.city ?? "");
    setZip(eFair.issuingPlace?.zipCode ?? "");
    setRemark(eFair.issuingPlace?.note ?? "");
    setProducts((eFair.products || []).map(({ __typename, ...p }) => p));
    setIssuingDays((eFair.issuingTime || []).map(({ __typename, ...d }) => d));
    setFiles(eFair.images ?? []);
    setNewProducts([]);
    setNewFiles([]);
  }, [eFair]);
  useEffect(() => {
    if (files.length > 0) {
      for (let i = 0; files.length > i; i++) {
        if (
          (files.length > 0 && files[i].delete === undefined) ||
          files[i].delete === false ||
          newFiles.length > 0 ||
          videoLink !== ""
        ) {
          setIsMandatory(true);
          break;
        } else {
          setIsMandatory(false);
        }
      }
    } else if (newFiles.length > 0 || videoLink !== "") {
      setIsMandatory(true);
    } else {
      setIsMandatory(false);
    }
    submitting && introText === ""
      ? setIntroTextValid(false)
      : setIntroTextValid(true);
    submitting && goal === "" ? setGoalValid(false) : setGoalValid(true);
    (submitting && contactEmail === "") ||
    !/^[^.]+(.[^.]+)*@([^.]+[.])+[a-z]{2,3}$/.test(contactEmail)
      ? setContactEmailValid(false)
      : setContactEmailValid(true);
    submitting && (from === null || from === "Invalid date")
      ? setFromValid(false)
      : setFromValid(true);
    submitting && (to === null || to === "Invalid date")
      ? setToValid(false)
      : setToValid(true);
    submitting && street === "" ? setStreetValid(false) : setStreetValid(true);
    submitting && town === "" ? setTownValid(false) : setTownValid(true);
    submitting && zip === "" ? setZipValid(false) : setZipValid(true);
    for (let i = 0; issuingDays.length > i; i++) {
      if (
        issuingDays.length === 0 ||
        (issuingDays.length === 1 && issuingDays[i].delete === true) ||
        issuingDays[i].from === "Invalid date" ||
        issuingDays[i].to === "Invalid date" ||
        issuingDays[i].from === null ||
        issuingDays[i].to === null
      ) {
        setIssuingDaysValid(false);
        break;
      } else {
        setIssuingDaysValid(true);
      }
    }
    for (let i = 0; newProducts.length > i; i++) {
      if (
        newProducts[i].name === "" ||
        newProducts[i].description === "" ||
        newProducts[i].price === "" ||
        newProducts[i].amount === "" ||
        newProducts[i].image === null ||
        newProducts[i].image?.length === 0
      ) {
        setNewProductsValid(false);
        break;
      } else {
        setNewProductsValid(true);
      }
    }
    for (let i = 0; products.length > i; i++) {
      if (products[i].delete === true) {
        setProductsValid(true);
        break;
      } else if (
        products[i].name === "" ||
        products[i].description === "" ||
        products[i].price === "" ||
        products[i].amount === "" ||
        products[i].image === null ||
        products[i].image?.length === 0
      ) {
        setProductsValid(false);
        break;
      } else {
        setProductsValid(true);
      }
    }
  }, [
    goal,
    from,
    to,
    street,
    town,
    zip,
    issuingDays,
    checked,
    newProducts,
    products,
    files,
    newFiles,
    videoLink,
    contactEmail,
    introTextValid,
    introText,
    submitting,
    managerNote
  ]);

  const providerValue = useMemo(
    () => {
      const addIssuingDayHandler = ({ index, ...day }) => {
        const updatedDays = [...issuingDays];
        updatedDays[index] = day;
        setIssuingDays(updatedDays);
      };

      const updateDateAndTime = (value, time, datetimeIndex) => {
        if (moment(value).isBefore(Date.now())) {
          addIssuingDayHandler({
            from: moment(Date.now()).format(),
            to: time.to,
            id: time.id,
            index: datetimeIndex
          });
        } else {
          addIssuingDayHandler({
            from: moment(value).format(),
            to: time.to,
            id: time.id,
            index: datetimeIndex
          });
        }
      };

      const removeIssuingDayHandler = (index) => {
        const updatedDays = [...issuingDays];
        const dayToRemove = updatedDays[index];
        if (dayToRemove.id) {
          updatedDays[index] = { ...dayToRemove, delete: true };
        } else {
          updatedDays.splice(index, 1);
        }
        setIssuingDays(updatedDays);
      };

      const addNewIssuingDayHandler = () => {
        if (issuingDays.length < 10) {
          setIsAddedNewIssuingDay(true);
          setIssuingDays([
            ...issuingDays,
            {
              from: null,
              to: null
            }
          ]);
        }
      };

      const addImagesHandler = (f) => {
        setNewFiles(f);
      };

      const updateProductHandler = ({ index, ...product }) => {
        const oldProducts = product.id ? products : newProducts;
        const updatedProducts = [...oldProducts];
        updatedProducts[index] = product;
        if (product.id) {
          setProducts(updatedProducts);
        } else {
          setNewProducts(updatedProducts);
        }
      };

      const removeProductHandler = ({ index, ...product }) => {
        if (product.id) {
          const updatedProducts = [...products];
          updatedProducts[index] = { ...updatedProducts[index], delete: true };
          setProducts(updatedProducts);
        } else {
          const updatedProducts = [...newProducts];
          updatedProducts.splice(index, 1);
          setNewProducts(updatedProducts);
        }
      };

      const recoverProductHandler = ({ index }) => {
        const updatedProducts = [...products];
        updatedProducts[index] = { ...updatedProducts[index], delete: false };
        setProducts(updatedProducts);
      };

      const deleteFileHandler = (index: number) => {
        const updatedFiles = [...files];
        updatedFiles[index] = { ...updatedFiles[index], delete: true };
        setFiles(updatedFiles);
      };

      const recoverFileHandler = (index: number) => {
        const updatedFiles = [...files];
        updatedFiles[index] = { ...updatedFiles[index], delete: false };
        setFiles(updatedFiles);
      };

      return ({
        addIssuingDayHandler,
        removeIssuingDayHandler,
        addNewIssuingDayHandler,
        addImagesHandler,
        updateProductHandler,
        removeProductHandler,
        recoverProductHandler,
        deleteFileHandler,
        recoverFileHandler,
        formDisabled,
        setFormDisabled,
        issuingDays,
        setIssuingDays,
        setChangeIssuingDayModalOpened,
        setChangeIssuingDayOnSubmitHandler,
        isAddedNewIssuingDay,
        hasChangeIssuingDayModalAlreadyDisplayed,
        setHasChangeIssuingDayModalAlreadyDisplayed,
        setIsAddedNewIssuingDay,
        updateDateAndTime,
        newProducts,
        setNewProducts,
        setProducts,
        products,
        from,
        files,
        goal,
        setGoal,
        introText,
        to,
        videoLink,
        newFiles,
        setNewFiles
      });
    },
    // eslint-disable-next-line max-len
    [formDisabled, setFormDisabled, issuingDays, setIssuingDays, setChangeIssuingDayModalOpened, setChangeIssuingDayOnSubmitHandler, isAddedNewIssuingDay, hasChangeIssuingDayModalAlreadyDisplayed, setHasChangeIssuingDayModalAlreadyDisplayed, setIsAddedNewIssuingDay, newProducts, setNewProducts, setProducts, products, from, files, goal, setGoal, introText, to, videoLink, newFiles, setNewFiles]
  );

  const submitFormHandler = () => {
    setFormDisabled(true);
    setLoading(true);
    submitEFairMutation({
      variables: {
        id: eFair.id,
        videoLink: youtubeEmbed(videoLink) ? youtubeEmbed(videoLink.trim()) : "",
        contactEmail: contactEmail,
        consent: checked,
        introText: introText,
        goal: goal,
        streetAndNumber: street,
        city: town,
        zipCode: parseInt(zip),
        note: remark,
        from: from,
        to: to,
        issuingTimeChangeReason: issuingTimeChangeReason,
        imagesToRemove: files.filter(f => f.delete).map(f => f.id),
        products: products.filter(p => p.delete).map(({ amount, price, image, ...p }) => ({
          ...p,
          ...(image
            ? {
              image,
              fileExt: image.type.replace("image/", "")
            }
            : {}),
          amount: parseInt(amount),
          price: parseInt(price)
        })),
        newProducts: [],
        issuingDays,

        managerNote: managerNote
      }
    })
      .then(async (res) => {
        setSaveStatus([{ type: 'success', text: 'Data aktualizována' }]);
        setIntroTextValid(true);
        setGoalValid(true);
        setContactEmailValid(true);
        setSubmitting(false);
        setFromValid(true);
        setToValid(true);
        setStreetValid(true);
        setTownValid(true);
        setZipValid(true);
        setNewProductsValid(true);
        setProductsValid(true);
        setIssuingDaysValid(true);

        // Creating new products
        const productsToCreate = newProducts?.map(({ amount, price, image, ...p }) => ({
          ...p,
          ...(image
            ? {
              image,
              fileExt: image.type.replace("image/", "")
            }
            : {}),
          amount: parseInt(amount),
          price: parseInt(price)
        }));
        if (newProducts?.length > 0) {
          setSaveStatus((s) => [...s, { type: 'info', text: 'Tvorba nových produktů' }]);
        }
        for (const product of productsToCreate) {
          setSaveStatus((s) => [...s, { type: 'info', text: `Tvorba produktu: ${product.name}` }]);
          await submitEFairNewProductMutation({
            variables: {
              ...product,
              eFairId: eFair.id,
            },
          })
          setSaveStatus((s) => [...s, { type: 'success', text: `Vytvořen produkt: ${product.name}` }]);
        }
        if (newProducts?.length > 0) {
          setSaveStatus((s) => [...s, { type: 'info', text: 'Tvorba nových produktů dokončena' }]);
        }

        const productsToUpdate = products.filter(p => !p.delete);
        if (productsToUpdate?.length > 0) {
          setSaveStatus((s) => [...s, { type: 'info', text: 'Aktualizace existujících produktů' }]);
        }
        for (const product of productsToUpdate) {
          setSaveStatus((s) => [...s, { type: 'info', text: `Aktualizace produktu: ${product.name}` }]);
          const { amount, price, image, ...p } = product;
          const productToUpdate = {
            ...p,
            ...(image
              ? {
                image,
                fileExt: image.type.replace("image/", "")
              }
              : {}),
            amount: parseInt(amount),
            price: parseInt(price)
          };
          await submitEFairUpdateProductMutation({ variables: productToUpdate });
          setSaveStatus((s) => [...s, { type: 'success', text: `Aktualizován produkt: ${product.name}` }]);
        }
        if (productsToUpdate?.length > 0) {
          setSaveStatus((s) => [...s, { type: 'info', text: 'Aktualizace existujících produktů dokončena' }]);
        }

        // Upload of files
        if (newFiles?.length > 0) {
          setSaveStatus((s) => [...s, { type: 'info', text: 'Nahrávání fotek z průběhu výroby' }]);
        }
        for (const file of newFiles) {
          setSaveStatus((s) => [...s, { type: 'info', text: `Nahrávání souboru ${file.name}` }]);
          await uploadEFairImageMutation({
            variables: {
              eFairId: eFair ? eFair.id : null,
              file,
              fileExt: file.type.replace("image/", "")
            }
          });
          setSaveStatus((s) => [...s, { type: 'success', text: `Nahrávání souboru ${file.name} dokončeno` }]);
        }
        if (newFiles?.length > 0) {
          setSaveStatus((s) => [...s, { type: 'info', text: `Soubory úspěšně nahrány` }]);
        }

        setSaveStatus((s) => [...s, { type: 'success', text: `Uložení úspěšně dokončeno` }]);
        setIsModalOpened(false);
        setLoading(false);
        refetch();
      }).catch((e) => {
        console.log(e);
        setIsModalOpened(false);
        setLoading(false);
        setFormDisabled(false);
        enqueueSnackbar("Při ukládání došlo k neočekávané chybě", {
          variant: "error",
          autoHideDuration: 6000,
          anchorOrigin: {
            horizontal: "center",
            vertical: "top"
          }
        });
        refetch();
      });
  };

  const validateForm = (e) => {
    e.preventDefault();
    setSubmitting(true);
    setTimeout(() => {
      if (
        introText !== "" &&
        goal !== "" &&
        contactEmail !== "" &&
        /^[^.]+(.[^.]+)*@([^.]+[.])+[a-z]{2,3}$/.test(contactEmail) &&
        (isMandatory ? checked : true) &&
        from !== null &&
        to !== null &&
        from !== "Invalid date" &&
        to !== "Invalid date" &&
        street !== "" &&
        town !== "" &&
        zip !== "" &&
        issuingDays.length > 0 &&
        newProductsValid &&
        productsValid &&
        issuingDaysValid
      ) {
        setIsModalOpened(true);
      } else {
        const mandatoryElements = document.getElementsByClassName("mandatory");
        for (let i = 0; i < mandatoryElements.length; i++) {
          if (
            (!!mandatoryElements[i] &&
              getComputedStyle(mandatoryElements[i]).getPropertyValue(
                "border"
              ) === "1px solid rgb(228, 31, 37)") ||
            getComputedStyle(mandatoryElements[i]).getPropertyValue("color") ===
              "rgb(228, 31, 37)"
          ) {
            mandatoryElements[i].scrollIntoView();
            const scrolledY = window.scrollY;

            if (scrolledY) {
              smoothscroll.polyfill();
              (window as any).__forceSmoothScrollPolyfill__ = true;
              window.scrollTo({
                top: scrolledY - 50,
                behavior: "smooth"
              });
            }
            break;
          }
        }
        enqueueSnackbar(
          "Vyplňte prosím všechna povinná pole (označená hvězdičkou)",
          {
            variant: "error",
            autoHideDuration: 4000,
            anchorOrigin: {
              horizontal: "center",
              vertical: "top"
            }
          }
        );
      }
    }, 500);
  };

  if (eFair) {
    return (
      <>
        <FormContext.Provider value={providerValue}>
          {isModalOpened ? (
            <>
              <NoteModal
                managerNote={managerNote}
                setManagerNote={setManagerNote}
                onSubmitHandler={submitFormHandler}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                loading={loading}
                saveStatus={saveStatus}
              />
            </>
          ) : null}
          {isChangeIssuingDayModalOpened ? (
            <>
              <ChangeIssuingDayModal
                reason={issuingTimeChangeReason}
                setReason={setIssuingTimeChangeReason}
                changeIssuingDayOnSubmitHandler={changeIssuingDayOnSubmitHandler}
                isModalOpened={isChangeIssuingDayModalOpened}
                setIsModalOpened={setChangeIssuingDayModalOpened}
                setIsAddedNewIssuingDay={setIsAddedNewIssuingDay}
                setHasChangeIssuingDayModalAlreadyDisplayed={setHasChangeIssuingDayModalAlreadyDisplayed}
              />
            </>
          ) : null}
          <FairForm>
            <FairState
              formState={!!eFair && eFair.approval ? eFair.approval : null}
            />
            <UpButtonsWrap>
              <SubmitButton
                disabled={formDisabled || loading}
                onClick={validateForm}
              >
                {loading ? <CircularProgress /> : null} Odeslat změny na
                schválení
              </SubmitButton>
              <RevertButton
                href="https://www.csas.cz/content/dam/cz/csas/www_csas_cz/dokumenty/abecedapenez/navod_ejarmark.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Návod
              </RevertButton>
            </UpButtonsWrap>
            {eFair.approval === "Zamítnuto" && eFair.approvalText ? (
              <>
                <CommentBox
                  title="Proč jsme neschválili poslední změny:"
                  text={eFair.approvalText}
                  bgColor="#be2321"
                />
              </>
            ) : null}
            {!!eFair.managerNote && eFair.managerNote !== "" ? (
              <>
                <CommentBox
                  title="Moje poznámka ke změnám:"
                  text={eFair.managerNote}
                  bgColor="#e1e1e1"
                  color="#313131"
                />
              </>
            ) : null}
            <GeneralInfo
              youtubeLink={videoLink}
              setYoutubeLink={setVideoLink}
              welcomeText={introText}
              setWelcomeText={setIntroText}
              fairGoal={goal}
              setFairGoal={setGoal}
              contactEmail={contactEmail}
              setContactEmail={setContactEmail}
              introTextValid={introTextValid}
              goalValid={goalValid}
              contactEmailValid={contactEmailValid}
              submitting={submitting}
            />
            <PhotoUpload
              isMandatory={isMandatory}
              // eFair={eFair} // TODO: unused???
              checked={checked}
              setChecked={setChecked}
              submitting={submitting}
            />
            <FlexImages>
              {(files || []).map((image, index) => (
                <div
                  key={image.id}
                  style={{
                    position: "relative",
                    opacity: image.delete ? "0.2" : "1"
                  }}
                >
                  <DeleteImageButton
                    disabled={formDisabled}
                    onClick={(e) => {
                      e.preventDefault();
                      if (image.delete) {
                        providerValue.recoverFileHandler(index);
                      } else {
                        providerValue.deleteFileHandler(index);
                      }
                    }}
                  >
                    {image.delete ? "Obnovit" : "x"}
                  </DeleteImageButton>
                  <Image
                    src={`/api/files/${image.path}`}
                    style={{ maxHeight: "150px" }}
                  />
                </div>
              ))}
            </FlexImages>
            <FairDate
              begins={from}
              setBegins={setFrom}
              ends={to}
              setEnds={setTo}
              fromValid={fromValid}
              toValid={toValid}
              submitting={submitting}
            />
            <Issuing
              id={eFair.id}
              street={street}
              setStreet={setStreet}
              town={town}
              setTown={setTown}
              zip={zip}
              setZip={setZip}
              remark={remark}
              setRemark={setRemark}
              eFair={eFair}
              streetValid={streetValid}
              townValid={townValid}
              zipValid={zipValid}
              submitting={submitting}
            />
            <ProducWrapper
              submitting={submitting}
              newProductsValid={newProductsValid}
            />
            <DownButtonsWrap>
              <SubmitButton
                disabled={formDisabled || loading || isModalOpened}
                onClick={validateForm}
              >
                {loading ? <CircularProgress /> : null} Odeslat změny na
                schválení
              </SubmitButton>
              <RevertButton
                href="https://www.csas.cz/content/dam/cz/csas/www_csas_cz/dokumenty/abecedapenez/navod_ejarmark.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Návod
              </RevertButton>
            </DownButtonsWrap>
          </FairForm>
        </FormContext.Provider>
      </>
    );
  } else {
    push("/login");
    return null;
  }
};

const eFairQuery = graphql<{ eFair: { id: number }}>(
  gql`
    query EFair($id: ID!) {
      eFair(id: $id) {
        id
        fairManager {
          id
          fullName
          email
        }
        state
        approval
        approvalText
        videoLink
        contactEmail
        goal
        introText
        from
        to
        managerNote
        images {
          id
          path
        }
        consent
        issuingPlace {
          id
          streetAndNumber
          city
          zipCode
          note
        }
        issuingTime {
          id
          from
          to
        }
        classroom {
          id
          classroomName
          school {
            id
            name
          }
        }
        products {
          id
          name
          description
          recognitionId
          price
          amount
          photoLink
        }
      }
    }
  `,
  {
    name: "eFairQuery",
    options: (props) => ({
      fetchPolicy: "cache-and-network",
      variables: {
        id: props.eFair.id
      }
    })
  }
);

const _uploadEFairImageMutation = graphql(
  gql`
    mutation UploadEFairImageMutation(
      $file: Upload!
      $eFairId: ID!
      $fileExt: String!
    ) {
      uploadEFairImage(
        data: { file: $file, eFairId: $eFairId, fileExt: $fileExt }
      ) {
        id
        name
        path
      }
    }
  `,
  {
    name: "uploadEFairImageMutation"
  }
);

export default [
  eFairQuery as any,
  submitEFairNewProductMutation as any,
  submitEFairUpdateProductMutation as any,
  _submitEFairMutation as any,
  _revertChangesMutation as any,
  _uploadEFairImageMutation as any,
  withRouter as any
].reduce((acc, x) => x(acc), FairFormBody) as ComponentType<FairFormBodyProps>;

const FairForm = styled.form`
  width: 90%;
  height: auto;
  padding: 40px;
  box-sizing: border-box;
  max-width: 1200px;
  background-color: white;
  box-shadow: 2px 2px 10px 1px grey;
  margin: 20px auto;
  border-radius: 7px;
  @media (max-width: 1113px) {
    padding: 20px;
  }
`;
const RevertButton = styled.a`
  background-color: transparent;
  border: 1px solid rgb( 40,112,237);
  color: rgb( 40,112,237);
  border-radius: 4px;
  padding: 14px 0 0 0;
  box-sizing: border-box;
  text-align: center;
  vertical-align: center;
  text-decoration: none;
  font-size: 0.875rem;
  outline: none;
  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
  width: 150px;
  height: 50px;
  float: right;
  margin: 30px 0;
  &:disabled {
    color: lightgrey;
    border: 1px solid lightgrey;
  }
  @media (max-width: 1113px) {
    width: 100%;
  }
`;
const SubmitButton = styled.button`
  color: #fff;
  background-color: #ff6129;
  &:hover {
    background-color: #fa4a11;
  }
  width: 230px;
  height: 50px;
  float: right;
  border: none;
  margin: 30px;
  border-radius: 4px;
  outline: none;
  &:disabled {
    background-color: lightgrey;
    color: white;
    border-bottom-color: #a6a6a6;
    box-shadow: inset 0 -2px 0 0 #a6a6a6;
  }
  @media (max-width: 1113px) {
    margin: 40px auto 0 auto;
    float: none;
    width: 100%;
  }
`;
const FlexImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  top: -40px;
`;
const Image = styled.img`
  flex-direction: row;
  max-width: 100%;
  height: auto;
  margin: 2px;
`;
const DeleteImageButton = styled.button`
  background-color: #e32024;
  color: white;
  border-radius: 4px;
  position: absolute;
  right: 5px;
  padding: 4px 9px;
  border: none;
  top: 5px;
  &:hover {
    background-color: #ff3300;
  }
  &:disabled {
    background-color: lightgrey;
  }
`;
const UpButtonsWrap = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  top: -65px;
  @media (max-width: 1113px) {
    width: 100%;
    position: static;
    margin-bottom: 100px;
  }
`;
const DownButtonsWrap = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  top: -65px;
  @media (max-width: 1113px) {
    width: 100%;
    top: -80px;
  }
`;
