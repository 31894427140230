import React from "react";

import SearchSection from "../containers/SearchSection.container";

const FairsFilter = () => (
  <>
    <SearchSection />
  </>
);

export default FairsFilter;
