import React, { FC, useState } from "react";
import styled from "styled-components";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import gql from "graphql-tag";
import { graphql, MutationFunction } from "react-apollo";
import { useSnackbar } from "notistack";
import openInNew from "../assets/images/open_in_new-24px.svg";

interface OrderModalBodyProps {
  product?: {
    id: string;
    pricePublic: number;
    amountPublic: number;
    namePublic: string;
    amount: number;
  };
  createReservationMutation?: MutationFunction<unknown, {
    fullName: string;
    email: string;
    amount: number;
    eFairId: string;
    productId: string;
    totalPrice: number;
    name: string;
  }>;
  eFair: {
    id: string;
  };
  refetch: () => unknown;
}

const OrderModalBody: FC<OrderModalBodyProps> = ({ product, createReservationMutation, eFair, refetch }) => {
  const [productCount, setProductCount] = useState<number | string>(1);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [checked, setChecked] = useState(false);
  const [verified, setVerified] = useState(false);
  // const [token, setToken] = useState(false);

  const eFairId = eFair.id;
  const productId = product.id;
  const totalPrice = +productCount * product.pricePublic;
  const { enqueueSnackbar } = useSnackbar();
  return (
    <>
      <ReservationForm
        onSubmit={(e) => {
          e.preventDefault();
          if (productCount <= product.amountPublic) {
            if (
              checked &&
              fullName &&
              email &&
              productCount !== "" &&
              productCount !== 0 &&
              productCount !== "0"
              // && verified
              // &&  !!token
            ) {
              createReservationMutation({
                variables: {
                  fullName,
                  email: email,
                  amount: +productCount,
                  eFairId,
                  productId,
                  totalPrice,
                  name: product.namePublic
                  // token: token,
                }
              })
                .then((res) => {
                  enqueueSnackbar("Vaše rezervace byla vytvořena!", {
                    variant: "success",
                    autoHideDuration: 4000,
                    anchorOrigin: {
                      horizontal: "center",
                      vertical: "top"
                    }
                  });
                  refetch();
                })
                .catch((e) => {
                  enqueueSnackbar("Rezervace nebyla vytvořena", {
                    variant: "error",
                    autoHideDuration: 4000,
                    anchorOrigin: {
                      horizontal: "center",
                      vertical: "top"
                    }
                  });
                });
            } else {
              enqueueSnackbar(
                "Musíte vyplnit všechny údaje, zakliknout seznámení s pravidly, mít vybraný aspoň jeden kus a nebýt robot :)",
                {
                  variant: "error",
                  autoHideDuration: 4000,
                  anchorOrigin: {
                    horizontal: "center",
                    vertical: "top"
                  }
                }
              );
            }
          } else if (product.amount === 0) {
            enqueueSnackbar(
              "Všechny výrobky jsou bohužel momentálně vyprodané",
              {
                variant: "error",
                autoHideDuration: 4000,
                anchorOrigin: {
                  horizontal: "center",
                  vertical: "top"
                }
              }
            );
          } else {
            enqueueSnackbar("Takový počet kusů bohužel není dostupný", {
              variant: "error",
              autoHideDuration: 4000,
              anchorOrigin: {
                horizontal: "center",
                vertical: "top"
              }
            });
          }
        }}
      >
        <CountLabel htmlFor="count">
          {`Počet kusů (k dizpozici ${product.amountPublic} ks`})
        </CountLabel>
        <Counter
          id="count"
          name="count"
          value={productCount}
          type="number"
          {...{ tabIndex: 1 }}
          inputProps={{ max: product.amountPublic, min: 0 }}
          onChange={(e) => {
            if (parseInt(e.target.value) < 1) {
              setProductCount(1);
            } else {
              setProductCount(e.target.value);
            }
          }}
        />
        <PriceWrap>
          <PriceLabel>Celková cena</PriceLabel>
          <TotalPrice>
            {productCount !== ""
              ? `${+product.pricePublic * +productCount} Kč`
              : "0 Kč"}
          </TotalPrice>
        </PriceWrap>
        <Hr />
        <Info>Vyplňte informace k jednorázové rezervaci</Info>
        <Label htmlFor="name">Celé jméno</Label>
        <CredentialsInput
          id="name"
          name="name"
          value={fullName}
          type="text"
          onChange={(e) => {
            setFullName(e.target.value);
          }}
        />
        <Label htmlFor="email">E-mail</Label>
        <CredentialsInput
          id="email"
          name="email"
          value={email}
          type="email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <Checkbox
          type="checkbox"
          id="conditions"
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
        <Gdpr>
          <span onClick={() => setChecked(!checked)}>
            *Seznámil/a jsem se s obsahem pravidel, více
          </span>
          <a
            href="https://www.csas.cz/content/dam/cz/csas/www_csas_cz/dokumenty/abecedapenez/pravidla_ejarmark.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" zde"}
            <img
              style={{
                marginLeft: "5px",
                width: "14px"
              }}
              src={openInNew}
            />
          </a>
        </Gdpr>
        {/* <CaptchaWrap>
          <Recaptcha
            sitekey='6LdaEyUaAAAAAO4lMhTGMfxiLCsdj8re1B66OcXE'
            render='explicit'
            onChange={(value) => {
              console.log("Captcha value: " + value);
            }}
            onloadCallback={() => {
              console.log("Recaptcha loaded!!");
            }}
            verifyCallback={(response) => {
              console.log(response);
              if (response) {
                setToken(response);
                setVerified(true);
              }
            }}
          />
        </CaptchaWrap> */}
        <ReservationButton type="submit">Rezervovat</ReservationButton>
      </ReservationForm>
    </>
  );
};
const createReservationMutation = graphql<any>(
  gql`
    mutation createReservation(
      $amount: Int!
      $fullName: String!
      $email: String!
      $totalPrice: Int!
      $eFairId: ID!
      $productId: ID! # $token: String
    ) {
      createReservation(
        data: {
          amount: $amount
          fullName: $fullName
          email: $email
          totalPrice: $totalPrice
          eFair: $eFairId
          product: $productId
          # token: $token
        }
      ) {
        amount
        fullName
        email
        totalPrice
        # token
        eFair {
          id
        }
        product {
          id
        }
      }
    }
  `,
  {
    name: "createReservationMutation",
    options: {
      fetchPolicy: "no-cache"
    }
  }
);

export default createReservationMutation(OrderModalBody)as any as FC<Omit<OrderModalBodyProps, "createReservationMutation">>;

const ReservationForm = styled.form`
  margin: 5% auto 5% auto; /* 5% from the top, 15% from the bottom and centered */
  width: 85%;
  height: auto;
`;
const Counter = styled(Input)`
  padding: 0 12px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  outline: 0;
  pointer-events: all;
  border-radius: 3px;
  font-weight: 200;
  display: inline-block;
  color: #313a45;
  background-color: #fff;
  background-image: none;
  border: 1px solid #929fa6;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 1px 3px 0 #b4bfc5 inset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-top: 5px;
  width: 60%;
  &:focus-within {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
  &:before,
  &:after,
  &:hover {
    border-bottom: 0;
  }
`;
const CountLabel = styled(InputLabel)`
  font-size: 0.9em;
  color: #282c34;
`;
const Hr = styled.hr`
  width: 100%;
  @media (max-width: 665px) {
    display: none;
  }
`;
const PriceWrap = styled.div`
  position: relative;
  top: -25px;
  float: right;
  text-align: center;
  @media (max-width: 665px) {
    font-size: 0.8em;
    top: -10px;
  }
`;
const PriceLabel = styled.p`
  margin-bottom: 5px;
  font-size: 0.8em;
`;
const TotalPrice = styled.p`
  font-weight: bold;
  font-family: OpenSansBold, sans-serif;
  font-size: 1.3em;
  margin: 0;
  top: 10px;
`;
const Info = styled.p`
  color: #0078b4;
  font-size: 0.9em;
  font-family: OpenSansBold;
  @media (max-width: 665px) {
    font-size: 0.7em;
    width: 100%;
  }
`;
const Label = styled.label`
  font-size: 0.9em;
  display: table;
`;
const CredentialsInput = styled.input`
  padding: 0.5rem 12px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  outline: 0;
  pointer-events: all;
  border-radius: 3px;
  font-weight: 200;
  display: inline-block;
  color: #313a45;
  background-color: #fff;
  background-image: none;
  border: 1px solid #929fa6;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 1px 3px 0 #b4bfc5 inset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  margin: 8px 0;
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(102, 175, 233, 0.6);
  }
`;
const Checkbox = styled.input`
  height: 20px;
  width: 20px;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  @media (max-width: 665px) {
    height: 15px;
    width: 15px;
    float: left;
  }
`;
const Gdpr = styled.label`
  font-size: 0.8em;
  color: grey;
  float: right;
  position: relative;
  top: 12px;
  width: 90%;
  @media (max-width: 665px) {
    //float: left;
    font-size: 0.5em;
    top: -15px;
  }
`;
const ReservationButton = styled.button`
  color: #fff !important;
  background-color: #1279b2;
  border-top-color: #1279b2;
  border-bottom-color: #072e62;
  box-shadow: inset 0 -2px 0 0 #072e62;
  outline: 0;
  //background-color: #0078b4;
  width: 140px;
  height: 35px;
  //color: white;
  float: right;
  border: none;
  margin-top: 5px;
  border-radius: 5px;
  outline: none;
  &:hover {
    border-bottom-color: #1279b2;
    box-shadow: inset 0 -2px 0 0 #1279b2;
  }
  @media (max-width: 665px) {
    margin-top: 0px;
  }
`;
const CaptchaWrap = styled.div`
  width: 90%;
  position: relative;
  left: 25px;
  margin: 20px auto;
`;
