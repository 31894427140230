import { createContext } from "react";

interface IFormContext {
  formDisabled: boolean;
  videoLink: string;
  introText: string;
  goal: string;
  from: any | null;
  to: any | null;
  issuingPlace?: {
    streetAndNumber: any | null;
    city: string;
    zipCode: string;
    note: string;
  };
  issuingDays: any[];
  products: any[];
  newFiles: any[];
  addIssuingDayHandler: (day: any) => unknown;
  updateDateAndTime: (value: any, time: any, datetimeIndex: any) => unknown;
  removeIssuingDayHandler: (index: number) => unknown;
  addNewIssuingDayHandler: () => unknown;
  setChangeIssuingDayModalOpened: (isOpened: boolean) => unknown;
  setChangeIssuingDayOnSubmitHandler: (_: any) => unknown;
  isAddedNewIssuingDay: boolean;
  hasChangeIssuingDayModalAlreadyDisplayed: boolean;
  setHasChangeIssuingDayModalAlreadyDisplayed: (isOpened: boolean) => unknown;
  setIsAddedNewIssuingDay: (isAdded: boolean) => unknown;
  addImagesHandler: (images: any[]) => unknown;
  updateProductHandler: (product: any) => unknown;
  removeProductHandler: (product: any) => unknown;
  recoverProductHandler: (product: any) => unknown;
  deleteFileHandler: (index: number) => unknown;
  recoverFileHandler: (index: number) => unknown;

  newProducts?: any[];
  setNewProducts?: (products: any[]) => unknown;
}

export const FormContext = createContext<IFormContext>({
  formDisabled: false,
  videoLink: "",
  introText: "",
  goal: "",
  from: null,
  to: null,
  issuingPlace: {
    streetAndNumber: null,
    city: "",
    zipCode: "",
    note: ""
  },
  issuingDays: [],
  products: [],
  newFiles: [],
  addIssuingDayHandler: (day) => {},
  updateDateAndTime: (value, time, datetimeIndex) => {},
  removeIssuingDayHandler: (index) => {},
  addNewIssuingDayHandler: () => {},
  setChangeIssuingDayModalOpened: (isOpened) => {},
  setChangeIssuingDayOnSubmitHandler: (_) => {},
  isAddedNewIssuingDay: false,
  hasChangeIssuingDayModalAlreadyDisplayed: false,
  setHasChangeIssuingDayModalAlreadyDisplayed: (isOpened) => {},
  setIsAddedNewIssuingDay: (isAdded) => {},
  addImagesHandler: (images) => {},
  updateProductHandler: (product) => {},
  removeProductHandler: (product) => {},
  recoverProductHandler: (product) => {},
  deleteFileHandler: (index) => {},
  recoverFileHandler: (index) => {}
});
