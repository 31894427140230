import { Box, Button, makeStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import gql from "graphql-tag";
import moment from "moment";
import React, { ComponentType, FC, ReactNode, useMemo, useState } from "react";
import { DataValue, graphql } from "react-apollo";
import Loader from "react-loader-spinner";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";

import arrow from "../assets/images/right_arrow.svg";
import searchImage from "../assets/images/search.svg";

interface Props {
  approvedEFairsQuery: DataValue<{ approvedEFairs?: Fair[] }>;
}

interface Fair {
  id: string;
  state: string;
  toPublic: string;
  to: string;
  classroom: {
    id: string;
    classroomName: string;
    schoolAddress: string;
    school: {
      id: string;
      street: string;
      name: string;
      city: string;
      address: string;
    };
  };
}

const SearchSection: FC<Props & RouteComponentProps> = ({
  approvedEFairsQuery: { approvedEFairs, loading },
  history: { push }
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [onlyActive, setOnlyActive] = useState(true);
  const [page, setPage] = useState(0);

  const allEfairs = useMemo(() => {
    const active = (
      onlyActive
        ? approvedEFairs?.filter(isFairActive)
        : approvedEFairs
    ) ?? [];
    return active.filter(searchFair(search));
  }, [approvedEFairs, onlyActive, search]);

  const efairs = useMemo(() => allEfairs.slice(page * 20, page * 20 + 20), [allEfairs, page]);

  const suffix = useMemo(() => efairs.length === 1 ? "jarmark" : efairs.length < 5 ? "jarmarky" : "jarmarků", [efairs.length]);

  return (
    <>
      <Wrapper>
        <InputWrapper>
          <Background>
            <Label htmlFor="search">
              Máme pro Vás {efairs.length} {suffix}, najděte si ten svůj...
            </Label>
            <br />
            <Icon src={searchImage} />
            <Input
              placeholder="Hledejte dle názvu nebo adresy školy..."
              name="search"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
            <SelectWrapper>
              <Select
                disableUnderline
                value={onlyActive ? "Jen aktivní" : "Všechny"}
                onChange={() => setOnlyActive(!onlyActive)}
                displayEmpty
                className={classes.select}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="Jen aktivní">Jen aktivní</MenuItem>
                <MenuItem value="Všechny">Všechny</MenuItem>
              </Select>
            </SelectWrapper>
          </Background>
        </InputWrapper>
      </Wrapper>
      <TableWrapper>
        {!loading && (
          <table>
            <tbody>
              {efairs.map(f => {
                const a = (x: ReactNode) => <Link to={`/detail/${f.id}`}>{x}</Link>;
                return (
                  <tr key={f.id} onClick={() => push(`/detail/${f.id}`)}>
                    <td>
                      {a(<strong>{f.classroom.school?.street}, {f.classroom.school?.city}</strong>)}
                    </td>
                    <td>{a(f.classroom.school?.name)}</td>
                    <td>{a(f.classroom.classroomName)}</td>
                    <td style={{ color: "grey" }}>{a(f.state)}</td>
                    <td>
                      {a(
                        <span
                          style={{
                            backgroundImage: `url(${arrow})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            width: "10px",
                            height: "15px",
                            position: "relative",
                            top: "4px",
                            display: "inline-block"
                          }}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={5}>
                  <div>
                    {!allEfairs.length ? 0 : page * 20 + 1} – {Math.min(allEfairs.length, (page + 1) * 20 + 1)} z {allEfairs.length}
                    <Button
                      style={page === 0 ? { opacity: 0.5, pointerEvents: "none" } : {}}
                      onClick={() => setPage(s => s - 1)}
                    />
                    <Button
                      style={(page + 1) * 20 >= allEfairs.length ? { opacity: 0.5, pointerEvents: "none" } : {}}
                      onClick={() => setPage(s => s + 1)}
                    />
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        )}
        {loading && (
          <Box display="flex" justifyContent="center" p={6}>
            <Loader type="ThreeDots" color="#00BFFF" height={20} width={50} />
          </Box>
        )}
      </TableWrapper>
      <br />
      <br />
    </>
  );
};

const approvedEFairsQuery = graphql(
  gql`
    query ApprovedEFairs {
      approvedEFairs {
        id
        state
        toPublic
        to
        classroom {
          id
          classroomName
          schoolAddress
          school {
            id
            street
            name
            city
            address
          }
        }
      }
    }
  `,
  {
    name: "approvedEFairsQuery",
    options: () => ({ fetchPolicy: "network-only" })
  }
);

export default approvedEFairsQuery(withRouter(SearchSection) as any) as ComponentType<{}>;

const isFairActive = (f: Fair) =>
  moment(Date.now()).isBefore(f.to) && f.state === "Aktivní";

const searchFair = (search: string) => (f: Fair) =>
  (f.classroom.school?.street ?? '').toLocaleLowerCase()
    .indexOf(search.toLocaleLowerCase()) !== -1 ||
  (f.classroom.school?.city ?? '')
    .toLocaleLowerCase()
    .indexOf(search.toLocaleLowerCase()) !== -1 ||
  (f.classroom.school?.name ?? '')
    .toLocaleLowerCase()
    .indexOf(search.toLocaleLowerCase()) !== -1;

const useStyles = makeStyles(() => ({
  select: {
    "marginLeft": "30px",
    "padding": "6px 10px 4px 15px",
    "border": "0.5px solid #004a7b",
    "borderRadius": "4px",
    "boxShadow": "1px 1px 5px 1px lightgrey",
    "background": "transparent",
    "content": "none",
    "color": "#004a7b",
    "&:hover": {
      border: "1px solid #002F5E",
      boxShadow: "1px 1px 5px 3px lightgrey"
    },
    "&:before": {
      borderBottom: "none"
    },
    "&:after": {
      borderBottom: "none"
    }
  }
}));

const Wrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  //background-color: #3f51b5;
  margin: 2rem auto;
  @media (max-width: 530px) {
    width: 100%;
    float: none;
    margin: 50px auto;
  }
`;

const SelectWrapper = styled.span`
  width: auto;
  @media (max-width: 1147px) {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    position: relative;
    left: -15px;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  //padding-left: 15%;
  margin: 0 auto;
  width: 70%;
  background-color: white;
  padding: 50px;
  @media (max-width: 1147px) {
    //padding: 0;
    text-align: center;
    position: static;
  }
`;

const Background = styled.div`
  //background-color: white;
`;

const Input = styled.input`
  box-shadow: 1px 1px 5px 1px lightgrey;
  margin: 10px auto;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #58809f;
    font-size: 16px;
  }
  :-ms-input-placeholder {
    color: #58809f;
    font-size: 16px;
  }
  outline: none;
  padding-left: 40px;
  width: 600px;
  height: 40px;
  border: 0.5px solid #004a7b;
  border-radius: 4px;
  background: transparent;
  color: #004a7b;

  @media (max-width: 1147px) {
    width: 90%;
    position: static;
    font-size: 0.9em;
    padding-left: 10px;
    &::placeholder {
      font-size: 0.9em;
      font-size: 0.9em;
    }
  }
  &:hover {
    border: 1px solid #002f5e;
    box-shadow: 1px 1px 5px 3px lightgrey;
  }
`;

const Icon = styled.img`
  position: absolute;
  left: 7%;
  top: 95px;
  width: 20px;
  height: 20px;
  @media (max-width: 1147px) {
    display: none;
  }
`;

const Label = styled.label`
  color: #58809f;
  font-size: 1.1em;
  font-family: OpenSansLight, sans-serif;
  margin-bottom: 15px;
  margin-top: 50px;
  @media (max-width: 530px) {
    text-align: center;
    width: 30%;
    font-size: 15px;
  }
`;

const TableWrapper = styled.div`
  max-width: 1200px;
  width: 90%;
  margin: 2rem auto;
  background: white;

  table {
    width: 100%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;

    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

    tbody {
      tr {
        td {
          width: auto;
          display: table-cell;
          padding: 16px;
          font-size: 0.875rem;
          text-align: left;
          font-family: "OpenSansRegular", sans-serif;
          font-weight: 400;
          line-height: 1.43;
          border-bottom: none;
          letter-spacing: 0.01071em;
          vertical-align: inherit;
          color: rgb(72, 72, 72);
          cursor: pointer;3

          strong {
            color: rgb(1, 75, 122);
            font-weight: bold;
            font-size: 1.1em;
          }

          a {
            text-decoration: none;
            color: inherit;
          }
        }
      }

      tr:nth-child(2n+1) {
        background-color: #f4f6fa;
      }
    }

    tfoot {
      td {
        padding: 0px 24px 0px 24px;

        div {
          float: right;
          font-size: 0.875rem;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          font-weight: 400;
          line-height: 1.43;
          letter-spacing: 0.01071em;
          display: flex;
          align-items: center;
          min-height: 52px;
          padding-right: 2px;

          * {
            margin-left: 16px;
          }

          button {
            min-width: 32px;
            padding: 12px;
            background-image: url(${arrow});
            background-repeat: no-repeat;
            background-position: center;
            transform: rotate(180deg);
          }

          button:last-child {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
`;
