import gql from "graphql-tag";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { graphql } from "react-apollo";
import Loader from "react-loader-spinner";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import ProductCard from "../components/ProductCard.component";
import { ReservationsState } from "../components/ReservationsState.component";
import MediaSection from "./MediaSection.container";
import OrderModal from "./OrderModal.container";

const FairDetailBody = ({
  eFairQuery: { eFair = {}, refetch, ...eFairQuery },
  history: { push }
}) => {
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState("");
  const [modalIsOpened, setModalIsOpened] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      !!eFair &&
      moment(eFair.from).isBefore(Date.now()) &&
      moment(eFair.to).isAfter(Date.now()) &&
      eFair.state === "Aktivní"
    ) {
      setIsActive(true);
      setLoading(false);
    } else if (eFair) {
      setLoading(false);
    }
  }, [eFair, isActive]);

  const onChange = (value) => {
    console.log("Captcha value:", value);
  };
  // This prevents the page from scrolling down to where it was previously.

  if (eFair !== null && eFair.state !== "Skrytý") {
    return (
      <>
        <Wrap>
          <OrderModal
            eFair={eFair}
            modalIsOpened={modalIsOpened}
            setModalIsOpened={setModalIsOpened}
            product={product}
            refetch={() => {
              refetch().then(() => {
                setModalIsOpened(false);
                const el = document.querySelector(`#id_product_${product.id}`);
                el.setAttribute("tabindex", "-1");
                el.focus();
                el.removeAttribute("tabindex");
              });
            }}
          />

          <Title>To jsme my</Title>
          <MediaSection eFair={eFair} push={push} />
          <Title>Naše výrobky</Title>
          {loading ? (
            <Loader type="ThreeDots" color="#00BFFF" height={20} width={50} />
          ) : (
            <ReservationsState isActive={isActive} eFair={eFair} />
          )}
          <ProductsWraper>
            {eFair
              ? (eFair.products || [])
                .filter((product) => product.photoLinkPublic !== null)
                .reverse()
                .map((product, index) => {
                  return (
                    <ProductCard
                      key={index}
                      product={product}
                      isActive={isActive}
                      modalIsOpened={modalIsOpened}
                      setModalIsOpened={setModalIsOpened}
                      setProduct={setProduct}
                      onClick={() => {
                        setProduct(product);
                        setModalIsOpened(true);
                      }}
                    />
                  );
                })
              : null}
          </ProductsWraper>
        </Wrap>
      </>
    );
  } else {
    push("/hledat");
    return null;
  }
};

const eFairQuery = graphql(
  gql`
    query EFair($id: ID!) {
      eFair(id: $id) {
        id
        classroom {
          id
          classroomName
          school {
            id
            name
          }
        }
        fairManager {
          id
          fullName
          email
        }
        state
        videoLinkPublic
        contactEmailPublic
        approval
        introTextPublic
        introText
        goalPublic
        goal
        fromPublic
        toPublic
        to
        from
        issuingPlace {
          id
          streetAndNumberPublic
          cityPublic
          zipCodePublic
          notePublic
        }
        products {
          id
          photoLinkPublic
          namePublic
          descriptionPublic
          pricePublic
          amountPublic
        }
        images {
          id
          pathPublic
        }
        issuingTime {
          fromPublic
          toPublic
        }
      }
    }
  `,
  {
    name: "eFairQuery",
    options: (props) => ({
      fetchPolicy: "network-only",
      variables: {
        id: props.eFair.id
      }
    })
  }
);

export default eFairQuery(withRouter(FairDetailBody));

const Wrap = styled.div`
  width: 1200px;
  //background-color: #3f51b5;
  display: block;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 90%;
  }
`;
const Title = styled.h2`
  margin-top: 30px;
  color: #484848;
  margin-left: 10px;
  font-family: OpenSansBold, sans-serif;
  @media (max-width: 665px) {
  }
`;
const ProductsWraper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto 50px auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: left;
  //background-color: #61dafb;
`;
