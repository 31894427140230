import React from "react";
import styled from "styled-components";

const NotFoundTitle = styled.p`
  margin-left: 15%;
  margin-top: 10vh;
  width: 50%;
  font-size: 3em;
  color: #014b7a;
`;
const NotFoundText = styled.p`
  margin-left: 15%;
  margin-bottom: 11vh;
  margin-top: 0;
  width: 50%;
  font-size: 1.3em;
  color: #014b7a;
`;

const NotFoundPage = () => {
  return (
    <>
      <NotFoundTitle>Chyba 404</NotFoundTitle>
      <NotFoundText>Je nám líto, ale tato stránka neexistuje.</NotFoundText>
    </>
  );
};

export default NotFoundPage;
